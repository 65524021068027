<script>
  import updateManager from '@/mixins/updateManager';
  import authorization from '@/mixins/authorization';
  import debug from '@/mixins/debug';
  import i18n from '@/locale/index.js';

  export default {
    mixins: [authorization, updateManager, debug],
    onLaunch: () => {
      // 微信小程序tabbar不支持i18n，动态更新文案
      // #ifdef APP-PLUS || MP-WEIXIN
      uni.setTabBarItem({
        index: 0,
        text: i18n.global.t('system.tabBar.stat'),
      })
      uni.setTabBarItem({
        index: 1,
        text: i18n.global.t('system.tabBar.home'),
      })
      uni.setTabBarItem({
        index: 2,
        text: i18n.global.t('system.tabBar.my'),
      })
      // #endif

    },
    methods: {}
  };
</script>

<style lang="scss">
  @import './uni_modules/vk-uview-ui/index.scss';
  @import '@/static/styles/uview-reset.scss';
  @import '@/static/styles/index.scss';
  @import '@/static/styles/mixins.scss';
  @import '@/static/styles/iconfont/iconfont.css';

  /* #ifndef APP-NVUE */
  page {
    height: 100%;
  }

  /* #endif */
</style>
