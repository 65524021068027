export const routePageConfig = {
  // #ifdef H5
  register: '/pages/account/register/index',
  // #endif
  // #ifndef H5
  register: '/page_subject/account/register/index',
  // #endif
};

export const WHITE_PAGE_LIST = [
  'pages/account/login/index',
  routePageConfig.register.slice(1),
  'page_subject/account/forget-password/index',
  'pages/account/clause/index',
  'pages/account/register-success/index',
  'pages/distribution/bind/index',
  'pages/distribution/bind/protocol/index',
  'pages/distribution/bind/default/index',
];

export const PERMISSION_CODE_MAP = {
  'page_subject/stat/overview/index': 'statSummary',
  'page_subject/stat/overview/saleDetail/index': 'statItemSale',
  'page_subject/stat/profitsOverview/index': 'orderProfitOverview',
  'page_subject/stat/profitDetail/index': 'orderBill',
  'page_subject/stat/productStatistics/index': 'statItemSummary',
  'page_subject/stat/salesStatistics/index': 'statItemSale',
  'page_subject/stat/orderStatistics/index': 'statOrderData',
  'page_subject/stat/shopStatistics/index': 'statOrderShop',
  'page_subject/stat/productSales/index': 'statWarehouseGoodsSale',
  'page_subject/stat/incomeAndExpenditureStatistics/index':
    'statWarehouseStockChange',
  'page_subject/stat/packagingMaterialsStatistics/index': 'statPackaging',
};
