//当前选择的仓库ID
export const CURRENT_SELECTED_WAREHOUSE_ID = 'currentSelectedWarehouseId';
//当前扫描模式
export const CURRENT_SCAN_MODE = 'currentScanMode';
// 用户选择的语言
export const LANGUAGE_MODE = 'languageMode';

// 当前选择的扫描发货设置
export const CURRENT_SELECTED_SCAN_SHIPMENT_SETTING =
  'currentSelectedScanShipmentSetting';

// 是否开启输入自动聚焦
export const CURRENT_INPUT_AUTO_FOCUS_SETTING = 'currentInputAutoFocusSetting';

// 分销邀请码
export const DISTRIBUTION_INVITE_CODE = 'distributionInviteCode';
