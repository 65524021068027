export default {
  "components": {
    "Account": {
      "UnReceivedCodeLink": {
        "5xz3hmxq1380": "ไม่ได้รับรหัสยืนยัน?",
        "5xz3hmxq4ws0": "ปิด",
        "5xz3hmxq5800": "อาจถูกระบุว่าเป็นสแปมโปรดตรวจสอบในสแปม",
        "5xz3hmxq5fc0": "โปรดตรวจสอบว่ากล่องจดหมายที่คุณป้อนถูกต้องหรือไม่หรือลองลงทะเบียนด้วยกล่องจดหมายอื่น",
        "5xz3hmxq5lo0": "โปรดตรวจสอบว่าปัญหาเครือข่ายปัจจุบันเป็นปกติหรือไม่",
        "5xz3hmxq5s40": "ไม่ได้รับรหัสยืนยัน?"
      }
    },
    "Empty": {
      "5weyj6xvl9o0": "ไม่มีข้อมูล"
    },
    "ProComponent": {
      "ProConfirmPopup": {
        "5weyne0t7t40": "เคล็ดลับ",
        "5weyne0tctw0": "กำหนด",
        "5weyne0td2o0": "ยกเลิก"
      }
    },
    "ScanCodeView": {
      "ScanCodeView": {
        "5weysudo0zk0": "หมายเลขผู้จัดส่งนี้มีอักขระที่ผิดกฎหมายโปรดสแกนอีกครั้ง"
      },
      "ScanCameraApp": {
        "5weas1do0zk0": "กำลังรับข้อมูลกล้อง"
      }
    },
    "SearchBackBar": {
      "5weyueldnb00": "คัดกรอง"
    },
    "SelectView": {
      "5weyy43t5v00": "โปรดป้อนคำค้นหา",
      "5weyy43tasw0": "ไม่มีข้อมูล"
    },
    "ShopTag": {
      "60aatigyeig0": "ข้ามพรมแดน"
    },
    "Warehouse": {
      "ScanModeSelect": {
        "5wez10tdkk80": "โปรดเลือกวิธีการสแกน",
        "5wez10tdpow0": "เลเซอร์สแกน",
        "5wez10tdpxg0": "กล้องสแกน"
      },
      "StockModeSelect": {
        "5wez1zd63kw0": "โปรดเลือกวิธีการจัดเก็บ",
        "5wez1zd68ag0": "โปรดเลือกวิธีการส่งออก"
      },
      "WarehouseSelect": {
        "5wez1mdf9v80": "โปรดเลือกคลังสินค้า",
        "5wez1mdfep80": "ไม่มีสิทธิ์คลังสินค้าโปรดติดต่อบัญชีหลักเพื่อเพิ่ม"
      }
    }
  },
  "constants": {
    "package": {
      "5wskfpirawg0": "ที่จะส่ง",
      "5wskfpirer00": "ล้มเหลว",
      "5wskfpirey40": "ประสบความสำเร็จ"
    },
    "platform": {
      "5zwog8ot6ow0": "手工订单",
      "5zwog8ot8zs0": "零售订单",
      "602uc1886b40": "Lazada Choice"
    },
    "shipment": {
      "5wsjxwq908g0": "สแกน1ครั้ง",
      "5wsjxwq96nc0": "สแกนค้นหาพัสดุและส่งการจัดส่ง",
      "5wsjxwq970k0": "สแกน2ครั้ง",
      "5wsjxwq97880": "หลังจากสแกนแล้วให้ค้นหาพัสดุก่อนสแกนอีกครั้งก่อนจะจัดส่งโดยอัตโนมัติ",
      "5wsjxwq97ek0": "คู่มือ",
      "5wsjxwq97lo0": "อัตโนมัติ",
      "5wsjxwq97s80": "ละเว้น",
      "5wsjxwq97yo0": "บังคับ",
      "5yjh2ncp5go0": "เปิด",
      "5yjh2ncp6p80": "ปิด"
    }
  },
  "pages": {
    "account": {
      "components": {
        "TwoFactorAuthenticationPopup": {
          "60ae807iv8k0": "ยืนยันการเข้าสู่ระบบอุปกรณ์อื่นๆ",
          "60ae807ivnc0": "ขณะนี้คุณกำลังเข้าสู่ระบบ",
          "60ae807ivq00": "จากที่อื่น",
          "60ae807ivq01": "",
          "60ae807ivrw0": "เพื่อความปลอดภัยของบัญชี โปรดทำการยืนยันให้เสร็จสิ้นก่อนเข้าสู่ระบบ",
          "60ae807ivtg0": "รับรหัสยืนยัน",
          "60ae807ivvs0": "ยืนยันทันที",
          "60ae807ivys0": "โปรดกรอกรหัสยืนยันทางอีเมล",
          "60ae807iw0s0": "โปรดกรอกรหัสยืนยันทาง SMS",
          "60ae807iw3o0": "โปรดป้อนรหัสยืนยัน!",
          "60ae807iw5w0": "กำลังรับรหัสยืนยัน",
          "60ae807iw7s0": "ส่งรหัสยืนยันสำเร็จ",
          "60ae8fkmn680": "ส่งใหม่อีกครั้ง{0}",
          "60gic918gtg0": "การตรวจสอบสำเร็จ",
          "60gicqrsmqk0": ""
        }
      },
      "currencyAndTimeZone": {
        "currencyAndTimeZone": {
          "index": {
            "60wmecfnlrg0": "สกุลเงิน",
            "60wmecfnmfo0": "โปรดเลือกสกุลเงิน",
            "60wmecfnmfo2": "ต้นทุนสินค้ากำไรและจำนวนอื่นๆในระบบจะแสดงเป็น {0} หลังจากเลือกแล้วจะไม่รองรับการแก้ไข",
            "60wmecfnmjs0": "โซนเวลา",
            "60wmecfnmms0": "โปรดเลือกเขตเวลา",
            "60wmecfnmpo0": "บันทึก",
            "60wmecfnms40": "เคล็ดลับ",
            "60wmecfnmuo0": "หลังจากเลือกสกุลเงินแล้วไม่รองรับการแก้ไขอีกคุณแน่ใจหรือว่าจะเลือกสกุลเงินปัจจุบัน?",
            "60wmecfnmws0": "บันทึกสำเร็จ",
            "60wmecfnlrg4": "เลือกสกุลเงินและโซนเวลา"
          }
        }
      },
      "forget-password": {
        "index": {
          "5weq2lrg6780": "หมายเลขโทรศัพท์มือถือ",
          "5weq2lrg7a40": "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
          "5weq2lrg7fg0": "รหัสผ่านใหม่",
          "5weq2lrg7io0": "กรุณาใส่รหัสผ่าน",
          "5weq2lrg7lk0": "รหัสผ่านมีอักขระอย่างน้อย8ตัวรวมทั้งอักขระตัวเลขและสัญลักษณ์พิเศษ",
          "5weq2lrg7os0": "ยืนยันรหัสผ่าน",
          "5weq2lrg7s00": "รหัสยืนยัน",
          "5weq2lrg7uw0": "โปรดป้อนรหัสยืนยัน",
          "5weq2lrg8580": "รีเซ็ตรหัสผ่าน",
          "5weq2lrg88k0": "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
          "5weq2lrg8bg0": "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน",
          "5weq2lrg8eg0": "กำลังรับรหัสยืนยัน",
          "5weq2lrg8h40": "ส่งรหัสยืนยันแล้ว",
          "5weq2lrg8jw0": "แก้ไขสำเร็จ"
        },
        "page": {
          "forgetPassword": "ลืมรหัสผ่าน"
        }
      },
      "login": {
        "5rnx1lpiie80": "บัญชี",
        "5rnx1lpiwp40": "รหัสผ่าน",
        "5rnx1lpiwz40": "เข้าสู่ระบบ",
        "5rnx1lpix540": "ลืมรหัสผ่าน",
        "5rnx1lpix9s0": "ลงทะเบียนบัญชี",
        "5rnx1lpixes0": "โปรดป้อนอีเมล/หมายเลขโทรศัพท์/บัญชีย่อย",
        "5rnx1lpixko0": "อีเมล/หมายเลขโทรศัพท์/บัญชีย่อยจำเป็น",
        "5rnx1lpixps0": "ไม่มีบัญชีโปรดป้อนใหม่",
        "5rnx1lpixus0": "รหัสผ่านที่จำเป็น",
        "5rnx1lpiy5g0": "รหัสผ่านไม่ถูกต้องโปรดป้อนใหม่",
        "5rnx1lpiyk00": "ไม่มีบัญชี",
        "5rnx1lpiyqc0": "รหัสผ่านไม่ถูกต้อง",
        "5rnx1lpiyv00": "รับรหัสยืนยัน",
        "5rnx1lpiyyc0": "รับอีกครั้งในไม่กี่วินาที",
        "5rnx1lpiz2g0": "รับอีกครั้ง"
      },
      "register": {
        "register": {
          "placeholder": {
            "email": "กรุณากรอกที่อยู่อีเมล",
            "password": "กรุณาใส่รหัสผ่าน",
            "password2": "ยืนยันรหัสผ่านอีกครั้ง",
            "mobile": "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
            "emailValidateCode": "กรุณากรอกรหัสยืนยันอีเมล",
            "mobileValidateCode": "โปรดป้อนรหัสยืนยันทางโทรศัพท์มือถือ"
          },
          "getValidateCodeBtn": "รับรหัสยืนยัน",
          "agreeCheckTipsBefore": "ฉันได้อ่านและเห็นด้วย",
          "agreeCheckTipsAfter": {
            "userAgreement": "ข้อตกลงการบริการผู้ใช้",
            "privacyAgreement": "ข้อตกลงความเป็นส่วนตัว"
          },
          "confirm": "ลงทะเบียนฟรี",
          "success": {
            "message": "ลงทะเบียนเรียบร้อยแล้ว",
            "tips": "ทำตามขั้นตอนต่อไปนี้เพื่อเข้าสู่ระบบและใช้ Easyboss",
            "copyLink": "คัดลอกลิงก์",
            "jumpToPc": "เข้าสู่อินเทอร์เฟซซอฟต์แวร์คอมพิวเตอร์"
          },
          "validatorError": {
            "password": "รหัสผ่านมีอักขระอย่างน้อย8ตัวรวมทั้งอักขระตัวเลขและสัญลักษณ์พิเศษ",
            "email": "รูปแบบกล่องจดหมายไม่ถูกต้อง",
            "mobile": "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
            "checkedAgree": "โปรดเลือกข้อตกลง",
            "passwordNotSame": "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน"
          },
          "gettingValidateCode": "กำลังรับรหัสยืนยัน",
          "validateCodeSendSuccess": "ส่งรหัสยืนยันแล้ว",
          "mobileCountryCode": "โปรดเลือกรหัสพื้นที่",
          "5weqrdhch7o0": "การลงทะเบียน",
          "5weqrdhci4w0": "รหัสผ่านมีอักขระอย่างน้อย8ตัวรวมทั้งอักขระตัวเลขและสัญลักษณ์พิเศษ",
          "5weqrdhci9k0": "ยืนยันรหัสผ่าน",
          "5weqrdhcicw0": "ฝูโจว Jianqiyun Network Technology Co., Ltd.",
          "5weqrdhcijs0": "สงวนลิขสิทธิ์",
          "5weqrdhcimw0": "Min ICP หมายเลข2020020145-5",
          "index": {
            "61f8z24qhjg0": "รหัสยืนยันกราฟิกไม่ถูกต้อง"
          }
        },
        "copy": {
          "success": "คัดลอกสำเร็จ"
        },
        "page": {
          "register": "การลงทะเบียน"
        },
        "greatboss": {
          "register": {
            "success": {
              "tips": "ทำตามขั้นตอนต่อไปนี้เพื่อเข้าสู่ระบบและใช้ Greatboss"
            }
          }
        }
      }
    },
    "distribution": {
      "bind": {
        "protocol": {
          "index": {
            "60j4x24i71g0": "\"Easyboss · คำแนะนำในการกระจายความร่วมมือ\"",
            "60j4x24i71g2": "เรียนผู้ใช้คุณสามารถใช้ฟังก์ชันความร่วมมือการกระจาย easyboss ได้! ก่อนที่จะใช้ฟังก์ชันความร่วมมือด้านการกระจาย easyboss คุณควรอ่านและปฏิบัติตามคำแนะนำความร่วมมือด้านการกระจาย easyboss อย่างละเอียด",
            "60j4x24i71g3": "1.ซัพพลายเออร์และผู้จัดจำหน่ายสามารถดำเนินธุรกิจความร่วมมือด้านการจัดจำหน่ายผ่านเครื่องมือการจัดจำหน่ายของ easyboss ทั้งสองฝ่ายร่วมมือกันบนพื้นฐานของความสมัครใจและความเป็นอิสระสำหรับการกระจายคำสั่งซื้อหลังการขายการระดมทุนและปัญหาอื่นๆที่เกี่ยวข้องที่เกิดจากความร่วมมือโปรดจัดเตรียมข้อตกลงที่เป็นมิตรระหว่างฝ่ายจัดจำหน่าย easyboss ไม่ให้การรับประกันใดๆและไม่มีภาระผูกพันใดๆสำหรับความร่วมมือระหว่างทั้งสองฝ่าย",
            "60j4x24i71g4": "2.ข้อมูลผลิตภัณฑ์ (รวมถึงแต่ไม่จำกัดเพียงราคาอุปทานคำอธิบายและคำอธิบายผลิตภัณฑ์รูปภาพที่เกี่ยวข้องวิดีโอฯลฯ) ที่อัปโหลดและแก้ไขใน easyboss จัดทำโดยซัพพลายเออร์ซัพพลายเออร์ควรตรวจสอบความถูกต้องตามกฎหมายของข้อมูลผลิตภัณฑ์และการกระทำของตนเองและไม่ละเมิดสิทธิ์ของบุคคลที่สามหากผลที่ตามมาทั้งหมดที่เกิดจากการกระทำข้างต้นของซัพพลายเออร์ซัพพลายเออร์จะต้องรับผิดชอบและ easyboss ขอสงวนสิทธิ์ในการดำเนินคดีที่เกี่ยวข้อง",
            "60j4x24i71g5": "3.หากคุณใช้ฟังก์ชัน \"การกระจายกองทุน\" ทั้งสองฝ่ายควรชำระเงินแบบออฟไลน์ easyboss ให้เฉพาะฟังก์ชันการทำบัญชีและไม่ให้บริการด้านการหักบัญชีการแยกบัญชีและบริการอื่นๆที่เกี่ยวข้องกับเงินทุนสำหรับการแลกเปลี่ยนเงินระหว่างทั้งสองฝ่ายและไม่รับผิดชอบต่อข้อพิพาทที่เกิดขึ้น",
            "60j4x24i71g6": "4.ผู้จัดจำหน่ายจะส่งคำสั่งซื้อไปยังซัพพลายเออร์เพื่อดำเนินการซัพพลายเออร์ล่าช้าในการจัดส่งหรือยกเลิกคำสั่งซื้อไม่ว่าด้วยเหตุผลใดก็ตามผลกระทบที่เกิดขึ้นโปรดแก้ไขโดยฝ่ายจัดจำหน่าย easyboss ไม่ได้ให้การรับประกันใดๆและไม่มีภาระผูกพันใดๆสำหรับความร่วมมือระหว่างทั้งสองฝ่าย",
            "60j4x24i71g8": "คำเชิญจัดจำหน่าย"
          }
        },
        "bind": {
          "index": {
            "60j51capb2s0": "ซัพพลายเออร์ {0} ขอเชิญคุณสร้างความร่วมมือกับเขาโปรดเลือกว่าจะตอบรับคำเชิญความร่วมมือหรือไม่",
            "60j51capb6s0": "หลังจากตอบรับคำเชิญแล้วโปรดอดทนรอให้ซัพพลายเออร์ตรวจสอบหลังจากผ่านการตรวจสอบแล้วคุณสามารถแสดงความร่วมมือได้",
            "60j51capb9g0": "คุณสามารถกรอกข้อมูลการสมัครเพื่อให้ส่งใบสมัครของคุณได้อย่างรวดเร็ว",
            "60j51capbcg0": "ฉันได้อ่านและเห็นด้วย",
            "60j51capbfk0": "ไม่รับ",
            "60j51capbi40": "ยอมรับ",
            "60j51capbks0": "คลิกที่นี่เพื่อเข้าสู่ระบบ",
            "60j51capbps0": "คลิก",
            "5yg2sdxxi8t0": "ซัพพลายเออร์ {0} ขอเชิญคุณสร้างความร่วมมือกับเขาโปรดลงชื่อเข้าใช้ easyboss ERP ก่อน",
            "60j51capbps1": "กำลังโหลด..."
          }
        },
        "hooks": {
          "useDistributionBind": {
            "5yg2wlqzuo00": "การดำเนินงานที่ประสบความสำเร็จ",
            "5yg2wlqzus40": "เคล็ดลับ",
            "5yg2wlqzuus0": "ฉันรู้แล้ว",
            "5yg2wlqzuxc0": "ลิงค์คำเชิญความร่วมมือหมดอายุแล้วโปรดติดต่อซัพพลายเออร์เพื่อขอลิงค์ความร่วมมือคำเชิญใหม่",
            "5yg2wlqzuxa0": "คุณได้สร้างความร่วมมือกับซัพพลายเออร์ {0}!",
            "5yg2wlqzuxt0": "คุณได้เริ่มการสมัครความร่วมมือกับซัพพลายเออร์ {0}! โปรดรอให้ซัพพลายเออร์ตรวจสอบ",
            "5yg2wlqzuxi0": "ซัพพลายเออร์ {0} ได้ยุติความร่วมมือกับคุณแล้วโปรดติดต่อซัพพลายเออร์",
            "5yg2wlqzuxp0": "ซัพพลายเออร์ {0} อยู่ในสถานะผิดปกติและไม่สามารถให้ความร่วมมือได้ชั่วคราว",
            "5yg2wlqzuxh0": "ซัพพลายเออร์ได้ปิดกระบวนการจัดจำหน่ายและไม่สามารถให้ความร่วมมือได้",
            "5yg2wlqzuyr0": "โปรดเข้าสู่ระบบด้วยบัญชีหลักเพื่อสร้างความสัมพันธ์แบบร่วมมือ",
            "5yg2wlqzuo01": "โปรดอ่านและยอมรับคำแนะนำสำหรับความร่วมมือในการจัดจำหน่าย"
          }
        },
        "DistributionCoopConfirmDialog": {
          "index": {
            "5zqoed6x0k40": "เคล็ดลับ",
            "5zqoed6x2500": "ฉันได้อ่านและเห็นด้วยกับ {0}",
            "5zqoed6x2ds0": "โปรดอ่านและยอมรับคำแนะนำสำหรับความร่วมมือในการจัดจำหน่าย"
          }
        },
        "default": {
          "index": {
            "60j4x24i71g6-1": "หากการดำเนินการสำเร็จโปรดไปที่พีซีเพื่อเข้าสู่ระบบ {0} เพื่อดู",
            "60j4x24i71g6-2": "คัดลอกลิงก์",
            "60j4x24i71g6-3": "คัดลอกสำเร็จ"
          }
        }
      },
      "constant": {
        "constant": {
          "distribution": {
            "5yg7j4qkamo0": "ความร่วมมือ",
            "5yg7j4qkb0o0": "ซัพพลายเออร์ถูกยกเลิก",
            "5yg7j4qkb3g0": "ผู้จัดจำหน่ายถูกยกเลิก",
            "5yg7j4qkb6c0": "ซัพพลายเออร์ปฏิเสธ",
            "5yg7j4qkb800": "ผู้จัดจำหน่ายปฏิเสธ",
            "5yg7j4qkb9s0": "กำลังรอการอนุญาตจากซัพพลายเออร์",
            "5yg7j4qkbco0": "รอการยอมรับจากผู้จัดจำหน่าย",
            "5yg7j4qkbe80": "ที่จะได้รับอนุญาต",
            "5yg7j4qkbfs0": "ปฏิเสธแล้ว",
            "5yg7j4qkbhg0": "ยกเลิกแล้ว",
            "5yg7j4qkbht0": "สถานะผิดปกติ"
          }
        }
      }
    },
    "my": {
      "my": {
        "components": {
          "InputAutoFocusSettingSelect": {
            "5yjh4axepj00": "โฟกัสอัตโนมัติของช่องป้อนข้อมูล"
          },
          "ScanShipmentSettingSelect": {
            "5wsklmc59bk0": "การสแกนการจัดส่ง"
          }
        }
      },
      "myIndex": {
        "5rnxe856up00": "ศูนย์ส่วนบุคคล",
        "5rnxe856vpg0": "รุ่นขนส่งสินค้า",
        "5rnxe856vw00": "รุ่นฟรี",
        "5rnxe856vzo0": "รุ่นจ่าย",
        "5rnxe856w380": "คลังสินค้า",
        "5rnxe856w780": "วิธีการสแกน",
        "5rnxe856wao0": "ออกจากระบบ",
        "5rnxe856whs0": "คุณต้องการออกจากระบบหรือไม่?",
        "5rnxe856wq40": "ภาษา",
        "5rnxe856wv40": "จำเป็นต้องรีสตาร์ทแอปพลิเคชันเพื่อสลับภาษา",
        "5rnxe856wha0": "สแกนการตั้งค่าการจัดส่ง",
        "5rnxe856whb0": "กำลังโหลด",
        "5rnxe856whb1": "เมื่อใช้กล้องสแกนโปรดอย่ากดปุ่มเลเซอร์สแกนอีกต่อไปอุปกรณ์อาจติดขัด",
        "5rnxe856whb2": "โฟกัสอัตโนมัติของช่องป้อนข้อมูล"
      }
    },
    "permission": {
      "404": {
        "60cyyf5ngl80": "ไม่มีสิทธิ์",
        "60cyyf5nhjo0": "ไม่มีสิทธิ์ โปรดติดต่อบัญชีหลักเพื่อเพิ่ม"
      }
    },
    "stat": {
      "HotGoodsSection": {
        "constant": {
          "5zmqkoscpn00": "ตาม SKU (สินค้าเดี่ยว)",
          "5zmqkoscqlo0": "ตาม SPU (สินค้า)"
        },
        "index": {
          "5zmr5k20gnk0": "สินค้าขายดีท็อป 10",
          "5zmr5k20i840": "ข้อมูลจำเพาะ",
          "5zmr5k20ifs0": "SKU แพลตฟอร์ม",
          "5zmr5k20ikg0": "ID สินค้า",
          "5zmr5k20iok0": "หมายเลขสินค้าหลัก",
          "5zmr5k20it00": "ร้าน",
          "5zmr5k20ixc0": "ยอดขาย",
          "5zmr5k20j1g0": "ปริมาณขาย"
        }
      },
      "HotPlatformSection": {
        "index": {
          "5zmr5t9re380": "5 แพลตฟอร์มขายดีที่สุด",
          "5zmr5t9rf3o0": "อันดับ",
          "5zmr5t9rf7k0": "แพลตฟอร์ม",
          "5zmr5t9rf9s0": "ยอดขาย"
        }
      },
      "HotShopSection": {
        "index": {
          "5zmr60rhpes0": "5 ร้านขายดีที่สุด",
          "5zmr60rhpyk0": "อันดับ",
          "5zmr60rhq100": "ร้าน",
          "5zmr60rhq300": "แพลตฟอร์ม",
          "5zmr60rhq4k0": "ยอดขาย"
        }
      },
      "StatShopSelect": {
        "index": {
          "5zmr8nz3oa40": "เลือกแพลตฟอร์ม",
          "5zmr8nz3pac0": "เลือกเว็บไซต์",
          "5zmr8nz3pio0": "เลือกร้าน"
        }
      },
      "TotalViewSection": {
        "constant": {
          "5zmqmu4tw1g0": "จำนวนเงินทั้งหมดที่ชำระ",
          "5zmqmu4twh40": "การรับเงินที่คาดว่าจะได้",
          "5zmqmu4twks0": "จำนวนลูกค้าที่ชำระเงิน",
          "5zmqmu4twns0": "เปรียบเทียบกับเมื่อวาน",
          "5zmqmu4twq80": "ปริมาณคำสั่งชำระเงิน",
          "5zmqmu4twuk0": "จำนวนลูกค้าคืนเงิน",
          "5zmqmu4twx00": "จำนวนคำสั่งซื้อคืนเงิน",
          "5zmqmu4twzg0": "จำนวนเงินคืนทั้งหมด",
          "5zmqmu4tx3o0": "กำไรโดยประมาณ",
          "5zmqmu4tx6g0": "อัตรากำไร"
        },
        "index": {
          "5zmr673frbk0": "ข้อมูลวันนี้ อัพเดททุก 2 ชั่วโมง"
        }
      },
      "common": {
        "60a99nlkohc0": "รายละเอียดกำไร",
        "60a9dq4u54g0": "การขายสินค้า",
        "60a9d45degg0": "สถิติผลิตภัณฑ์",
        "60a9eurfpww0": "สถิติวัสดุบรรจุ",
        "60a9feawjog0": "สถิติร้าน",
        "60a9ht0fpgc0": "ตัวกรองวันที่",
        "60a9lb8cd0w0": "หน่วยเงิน",
        "60a9lb8cdko0": "ตามสถานะการสั่งซื้อ",
        "60a9rrnp5rs0": "สถิติข้อมูล",
        "5saylxfm3ps0": "ระยะเวลาสูงสุด 3 เดือน โปรดเลือกเวลาอีกครั้ง"
      },
      "components": {
        "CoreIndicator": {
          "5zmr3p5s6280": "指標หลัก",
          "5zmr3p5s6s40": "ยอดขาย"
        },
        "CoreIndicatorChart": {
          "5zmr3x26fdo0": "แนวโน้มขององค์ประกอบหลัก",
          "5zmr3x26g500": "สถิติผลิตภัณฑ์",
          "5zmr3x26g8g0": "เปอร์เซ็นต์ (%)"
        },
        "DataStatisticsSection": {
          "5zmr2qhmwkw0": "สถิติข้อมูล"
        },
        "DisbursementChartCard": {
          "5zmr43sgo9o0": "การวิเคราะห์ค่าใช้จ่าย",
          "5zmr43sgomg0": "เปอร์เซ็นต์ {0}: {1}%",
          "5zmr43sgopc0": "ค่าใช้จ่ายทั้งหมด"
        },
        "GoodsItemSection": {
          "5zmr895tvq80": "สินค้า SKU",
          "60kms1u2s700": "ข้อกำหนด"
        },
        "GoodsSection": {
          "5zmr2yq2c0k0": "รหัสสินค้า",
          "5zmr2yq2cek0": "หมายเลขสินค้าหลัก",
          "5zmr2yq2ch80": "แพลตฟอร์ม",
          "5zmr2yq2cj00": "ร้าน",
          "5zmr2yq2ckk0": "ปริมาณคำสั่งชำระเงิน",
          "5zmr2yq2cm80": "ปริมาณขาย",
          "5zmr2yq2cns0": "ยอดชำระทั้งหมด",
          "5zmr2yq2cpc0": "ยอดคืนทั้งหมด",
          "5zmr2yq2cr00": "ข้อมูลจำเพาะ",
          "5zmr2yq2css0": "SKU แพลตฟอร์ม"
        },
        "OrderDistributionSection": {
          "5zmr7cert4c0": "การกระจายการสั่งชำระเงิน"
        },
        "ProductDistributionSection": {
          "5zmr36t00b80": "การกระจายสินค้าที่ bán được"
        },
        "RankingTenList": {
          "5zmr4euc2940": "การวิเคราะห์กำไร 10 อันดับแรก",
          "5zmr4euc2z40": "การจัดอันดับ",
          "5zmr4euc3300": "กำไร",
          "5zmr4euc35s0": "ยอดขาย",
          "5zmr4euc3880": "อัตรากำไรจาการขาย",
          "5zmr4euc3bs0": "กำไรทั้งหมด"
        },
        "StatDropdown": {
          "5zmr95mf3q00": "ที่เลือก",
          "5zwtjniwy940": "หนึ่ง"
        },
        "StatSearchBar": {
          "5zmr9lrwj5g0": "กรุณาใส่",
          "5zmr9lrwknc0": "คัดกรอง"
        },
        "TableSection": {
          "5zmr7j0011s0": "วันที่",
          "5zmr7j001yw0": "จำนวนลูกค้า",
          "5zmr7j002340": "จำนวนคำสั่งซื้อ",
          "5zmr7j002680": "ยอดรวมคำสั่งซื้อ",
          "5zmr7j0028s0": "ยอดคืนเงิน"
        },
        "StatDetailPopup": {
          "5zmw7ihk8580": "รีเซ็ต",
          "5zmw7ihk9n80": "กำหนด"
        },
        "QueryBar": {
          "5saylxfm3p80": "ระยะเวลาสูงสุด 3 เดือน โปรดเลือกเวลาอีกครั้ง",
          "5saylxfm4400": "รองรับการสืบค้นข้อมูลในช่วง 3 เดือนที่ผ่านมาเท่านั้น โปรดเลือกเวลาอีกครั้ง"
        },
        "StatRadio": {
          "60aa4y1tqd40": "ที่เลือก",
          "60aa4y1tqq80": "หนึ่ง",
          "60aa4y1tqss0": "ทั้งหมด"
        },
        "StatEmpty": {
          "60krhr1rgwk0": "ไม่มีข้อมูล"
        }
      },
      "constant": {
        "5zmp81p6xo00": "ปริมาณคำสั่งชำระเงิน",
        "5zmp81p6yuk0": "ยอดชำระทั้งหมด",
        "5zmp81p6yzs0": "ปริมาณคำสั่งคืน",
        "5zmp81p6z2w0": "ยอดคืนทั้งหมด",
        "5zmp81p6z5s0": "ภาพรวมข้อมูล",
        "5zmp81p6z9k0": "ภาพรวมกำไร",
        "5zmp81p6zcg0": "รายละเอียดกำไร",
        "5zmp81p6zlk0": "สถิติผลิตภัณฑ์",
        "5zmp81p6zug0": "สถิติปริมาณขาย",
        "5zmp81p70840": "สถิติคำสั่งซื้อ",
        "5zmp81p70fk0": "สถิติร้าน",
        "5zmp81p70j80": "การขายสินค้า",
        "5zmp81p70lg0": "สถิติรายรับรายจ่าย",
        "5zmp81p70o00": "สถิติวัสดุบรรจุ",
        "5zmp81p70q40": "ตามวัน",
        "5zmp81p70sc0": "ตามสัปดาห์",
        "5zmp81p70uk0": "ตามเดือน",
        "5zmp81p70wg0": "เมื่อวาน",
        "5zmp81p70y80": "7 วันที่ผ่านมา",
        "5zmp81p710g0": "30 วันที่ผ่านมา",
        "5zmp81p713o0": "วันที่กำหนดเอง",
        "5zmp81p715c0": "ยอดคืนเงิน",
        "5zmp81p719c0": "เวลาสั่งซื้อ",
        "5zmp81p71b00": "เวลาชำระเงิน",
        "5zmp81p71dk0": "เวลาชำระเงิน",
        "5zmp81p71f80": "เวลาจัดส่ง",
        "5zmp81p71jg0": "วันสุดท้ายสำหรับการจัดส่ง",
        "5zmp81p71ok0": "ยอดขาย",
        "5zmp81p71rg0": "จำนวนเงินที่คาดว่าจะได้รับกลับ",
        "5zmp81p71t80": "การชำระเงินจริงๆ โดยผู้ซื้อ",
        "5zmp81p71v40": "ต้นทุนสินค้า",
        "5zmp81p71ws0": "ค่าใช้จ่ายอื่นๆ",
        "5zmp81p71yg0": "ค่าบริการบรรจุ",
        "5zmp81p71zs0": "กำไร",
        "5zmp81p72180": "อัตรากำไรต่อต้นทุน",
        "5zmp81p72340": "อัตรากำไรจาการขาย",
        "5zmp81p724w0": "ตามแพลตฟอร์ม",
        "5zmp81p726c0": "ตามเว็บไซต์",
        "5zmp81p72800": "ตามร้าน",
        "5zmp81p729c0": "ร้าน",
        "5zmp81p72aw0": "แพลตฟอร์ม",
        "5zmp81p72f00": "เว็บไซต์",
        "6021uie62280": "ปริมาณขาย"
      },
      "hooks": {
        "5zmp74as2c00": "กำลังโหลด"
      },
      "incomeAndExpenditureStatistics": {
        "constant": {
          "5zmqp7c2t700": "สินค้าคงเหลือปลายงวด",
          "5zmqp7c2u6c0": "สินค้าเริ่มต้น",
          "5zmqp7c2udo0": "การซื้อเข้า",
          "5zmqp7c2uhw0": "การปรับปรุงสินค้าเข้า",
          "5zmqp7c2ulk0": "การเก็บข้อมูลเข้า",
          "5zmqp7c2uq80": "การโอนสินค้าเข้า",
          "5zmqp7c2uu80": "การคืนสินค้าเข้า",
          "5zmqp7c2uyg0": "การคืนสินค้าด้วยมือเข้า",
          "5zmqp7c2v3o0": "อื่นๆ เข้า",
          "5zmqp7c2v800": "การขายออก",
          "5zmqp7c2vc40": "การปรับปรุงสินค้าออก",
          "5zmqp7c2vgg0": "การเก็บข้อมูลออก",
          "5zmqp7c2vkw0": "การโอนสินค้าออก",
          "5zmqp7c2vp40": "การขายด้วยมือ",
          "5zmqp7c2vtk0": "อื่นๆ ออก",
          "5zmqp7c2vzw0": "สินค้า SKU",
          "5zmqp7c2w480": "ชื่อสินค้า"
        },
        "hooks": {
          "5zmr7yzqthc0": "ตัวกรองวันที่",
          "5zmr7yzqtsw0": "เลือกคลังสินค้า"
        },
        "index": {
          "5zmr7qd04is0": "สถิตินำเข้าและส่งออก"
        }
      },
      "index": {
        "5zmp4zbj9s00": "ข้อมูลแบบเรียลไทม์",
        "5zmp4zbjazc0": "ข้อมูลประจำวัน, อัพเดททุก 2 ชั่วโมง",
        "5zmp4zbjb3k0": "ฟังก์ชันทั่วไป",
        "5zmvhhwoxlw0": "หน่วยเงิน"
      },
      "orderDetail": {
        "constant": {
          "60a9oxflttc0": "รายได้จากการสั่งซื้อ",
          "60a9oxflu740": "จำนวนเงินคำสั่งซื้อเดิม",
          "60a9oxflu9o0": "ราคาสินค้า",
          "60a9oxfluc40": "ค่าขนส่งทั้งหมด",
          "60a9oxfluds0": "ค่าขนส่งที่ผู้ซื้อจ่าย",
          "60a9oxflufk0": "ค่าขนส่งที่ผู้ขายจ่าย",
          "60a9oxfluhc0": "ส่วนลด",
          "60a9oxfluiw0": "ค่าธรรมเนียมและค่าใช้จ่าย",
          "60a9oxfluko0": "คอมมิชชั่น",
          "60a9oxflum40": "ค่าบริการ",
          "60a9oxflunw0": "ค่าธรรมเนียมการทำธุรกรรม",
          "60a9oxflupk0": "จำนวนเงินที่จ่ายจริงโดยผู้ซื้อ",
          "60a9oxflur40": "ค่าจัดส่ง",
          "60a9oxflut80": "ค่าบรรจุภัณฑ์",
          "60a9oxfluus0": "ค่าใช้จ่ายอื่นๆ",
          "60a9oxfluwg0": "ค่าสินค้า",
          "60a9oxfluyc0": "กำไรโดยประมาณ",
          "60a9pmw41sg0": "การสนับสนุนค่าขนส่ง Shopee",
          "60a9qjl9dao0": "คูปองของผู้ขาย",
          "60a9qtr2gzk0": "การหักเหลือเหรียญ Shopee"
        },
        "index": {
          "60a9ncf9km00": "รายละเอียดคำสั่งซื้อ",
          "60a9ncf9kys0": "หมายเลขสั่งซื้อ:",
          "60a9ncf9l180": "หน่วยเงินที่ใช้ในปัจจุบัน:",
          "60a9ncf9l2w0": "เว็บไซต์:",
          "60a9ncf9l4o0": "อัตราแลกเปลี่ยน:"
        }
      },
      "orderStatistics": {
        "constant": {
          "5zmqukt4k5w0": "ตามร้าน",
          "5zmqukt4loo0": "ช่วงราคา",
          "5zmqukt4lu40": "วันที่",
          "5zmqukt4ly80": "จำนวนลูกค้า (คน)",
          "5zmqukt4m1s0": "จำนวนคำสั่งซื้อ (ธุรกรรม)",
          "5zmqukt4m600": "ยอดรวมคำสั่งซื้อ",
          "5zmqukt4m980": "ยอดรวมการชำระเงิน",
          "5zmqukt4mcw0": "จำนวนคำสั่งซื้อที่ชำระเงิน (ธุรกรรม)",
          "5zmqukt4mfo0": "จำนวนลูกค้าที่ชำระเงิน",
          "5zmqukt4mi40": "ยอดรวมเงินทอน",
          "5zmqukt4mlw0": "จำนวนคำสั่งซื้อคืนเงิน (ธุรกรรม)",
          "5zmqukt4mqs0": "จำนวนลูกค้าได้รับการคืนเงิน",
          "5zmqukt4muc0": "ค่าเฉลี่ยต่อการสั่งซื้อ",
          "5zmqukt4nj40": "จำนวนคำสั่งซื้อที่ถูกต้อง",
          "5zmqukt4o100": "ยอดขายที่ถูกต้องทั้งหมด",
          "5zmqukt4o134": "{0} หมวดหมู่ SKU",
          "5zmqukt4o155": "{0} หรือมากกว่าหมวดหมู่ SKU",
          "5zmqukt4o177": "จำนวนหมวดหมู่ SKU"
        },
        "hooks": {
          "5zmr6ze850o0": "ตัวกรองวันที่"
        },
        "index": {
          "5zmr6pphrw00": "สถิติการสั่งซื้อ",
          "5zmr6pphsek0": "คัดกรอง",
          "5zmr6pphsi40": "กราฟแนวโน้มตัวชี้ข้อมูล"
        }
      },
      "overview": {
        "constant": {
          "5zmqj6xm7gs0": "ข้อมูลแบบเรียลไทม์",
          "5zmqj6xm8840": "แพลตฟอร์มขายดี",
          "5zmqj6xm8bs0": "ร้านค้าขายดี",
          "5zmqj6xm8ec0": "สินค้าขายดี"
        },
        "index": {
          "5zmr4qhws3s0": "ภาพรวมข้อมูล"
        }
      },
      "packagingMaterialsStatistics": {
        "components": {
          "60aa48gpg940": "สถิติข้อมูล"
        },
        "constant": {
          "60aa3i4w8840": "ปริมาณบรรจุทั้งหมด",
          "60aa3i4w8mk0": "ปริมาณการใช้งานของคำสั่งซื้อ",
          "60aa3i4w91s0": "จำนวนทั้งหมด",
          "60aa3i4w9940": "รหัส",
          "60aa3i4w9b00": "ชื่อ",
          "60aa3i4w9cs0": "อันดับ",
          "60aa3i4w9e80": "ราคาเฉลี่ยต่อหน่วย",
          "60aa3i4w9g00": "ผลรวม"
        }
      },
      "productSales": {
        "components": {
          "60aa1gn2j3o0": "ข้อมูลจำเพาะ",
          "60aa1gn2jms0": "สินค้า SKU",
          "60aa24ijr500": "สถิติข้อมูล"
        },
        "constant": {
          "60aa0cmsgck0": "จำนวนประเภทสินค้า",
          "60aa0cmsgwk0": "ปริมาณขายสินค้า",
          "60aa0cmsh000": "ค่าสินค้า",
          "60aa0cmsh280": "จำนวนสินค้าที่คืน",
          "60aa0cmsh4g0": "จำนวนเงินคืน",
          "60aa0cmsh780": "ยอดขาย",
          "60aa0cmsh9s0": "ค่าใช้จ่ายสำหรับการคืนเงิน",
          "60aa0cmshbw0": "จำนวนคำสั่งซื้อที่ขายได้",
          "60aa0vmcdtw0": "สินค้า SKU",
          "60aa0vmcec00": "ชื่อสินค้า"
        }
      },
      "productStatistics": {
        "components": {
          "60a9yyz55n00": "กราฟแนวโน้มตัวชี้ข้อมูล",
          "60a9yyz565o0": "เปอร์เซ็นต์ (%)"
        },
        "constants": {
          "60a9xumyah40": "วันที่",
          "60a9xumyav40": "สินค้าทั้งหมด",
          "60a9xumyay40": "จำนวนสินค้าที่ขายได้",
          "60a9xumyb000": "สัดส่วนสินค้าที่ขายได้",
          "60a9xumyb1s0": "จำนวนสินค้าที่ขายได้",
          "60a9xumyb5k0": "อัตราการหมุนเวียน",
          "60a9xumyb740": "จำนวนสินค้าใหม่ที่ขายได้",
          "60a9xumyb8s0": "สัดส่วนสินค้าใหม่ที่ขายได้"
        }
      },
      "profitDetail": {
        "components": {
          "60a9u37djso0": "รายละเอียดคำสั่งซื้อ",
          "60a9u37dkc40": "รายละเอียดร้านค้า",
          "60a9vuvtmfs0": "ร้านค้า:",
          "60a9vuvtms80": "เว็บไซต์:",
          "60a9wn2oggk0": "ID สินค้า",
          "60a9wn2ogv80": "SKU ของแพลตฟอร์ม",
          "60a9wn2ogz40": "สินค้า SKU",
          "60a9wn2oh1s0": "ค่าสินค้า",
          "60a9wn2oh4s0": "จำนวนเงินที่ถูกคืนโดยแพลตฟอร์ม:",
          "60a9wn2oh7g0": "กำไร:",
          "60a9wn2oha00": "อัตรากำไรจากการขาย:"
        },
        "constant": {
          "60a9mn7mnho0": "ตามรายละเอียดคำสั่งซื้อ",
          "60a9mn7mo0g0": "ตามร้าน",
          "60a9mn7mo4c0": "หมายเลขสั่งซื้อ",
          "60a9mn7mo780": "หมายเลขบรรจุ",
          "60a9mn7mo9g0": "หมายเลข waybill",
          "60a9mn7mod80": "ชื่อสินค้า",
          "60a9mn7mofk0": "ID สินค้า",
          "60a9mn7mohs0": "SKU ของแพลตฟอร์ม",
          "60a9mn7mok40": "สินค้า SKU",
          "60a9mn7momg0": "เวลาสั่งซื้อ",
          "60a9mn7mook0": "เวลาชำระเงิน",
          "60a9mn7mor40": "เวลาชำระเงิน",
          "60a9mn7mouc0": "เวลาจัดส่ง",
          "60a9mn7moww0": "เวลาสุดท้ายสำหรับการจัดส่ง",
          "60a9skxqv140": "จำนวนคำสั่งซื้อ",
          "60a9skxqvhc0": "ยอดขาย",
          "60a9skxqvko0": "การชำระเงินจริงๆ โดยผู้ซื้อ",
          "60a9skxqvn80": "จำนวนเงินที่คาดว่าจะได้รับกลับ",
          "60a9skxqvpo0": "จำนวนที่ชำระแล้ว",
          "60a9skxqvs00": "จำนวนที่ยังค้างชำระ",
          "60a9skxqvug0": "ค่าใช้จ่าย",
          "60a9skxqvyo0": "ค่าสินค้า",
          "60a9skxqw0o0": "ค่าใช้จ่ายโฆษณา",
          "60a9skxqw380": "ค่าใช้จ่ายในการดำเนินงาน",
          "60a9skxqw5g0": "ค่าใช้จ่ายในการบรรจุ",
          "60a9skxqw7w0": "ค่าใช้จ่ายอื่นๆ",
          "60a9skxqwbg0": "กำไร",
          "60a9skxqwdw0": "อัตรากำไรต่อต้นทุน",
          "60a9skxqwgg0": "อัตรากำไรจากการขาย",
          "60a9skxqwiw0": "ข้อมูลวันนี้",
          "60a9skxqwl40": "ข้อมูลเมื่อวาน",
          "60a9skxqwnw0": "ข้อมูลสรุป",
          "60a9v7pp1n00": "จำนวนเงินที่คาดว่าจะชำระ",
          "60a9v7pp2qs0": "จำนวนเงินเดิมของคำสั่งซื้อ",
          "60a9v7pp30g0": "การชำระเงินจริงๆ โดยผู้ซื้อ",
          "60a9v7pp33g0": "ค่าบรรจุภัณฑ์",
          "60a9v7pp3680": "ค่าใช้จ่ายอื่นๆ",
          "60a9v7pp38w0": "กำไร",
          "60a9v7pp3bo0": "อัตรากำไรต่อต้นทุน",
          "60a9v7pp3fo0": "อัตรากำไรจากการขาย",
          "60y9bytj9u80": "ค่าธุรกิจคลังสินค้าของบุคคลที่สาม",
          "60y9bytjb480": "ค่าบริการขนส่งคลังสินค้าของบุคคลที่สาม"
        }
      },
      "profitsOverview": {
        "constant": {
          "5zmqie08y6k0": "ตัวชี้หลัก",
          "5zmqie08yp40": "แนวโน้มของตัวชี้หลัก",
          "5zmqie08ytg0": "การวิเคราะห์ค่าใช้จ่าย",
          "5zmqie08ywk0": "การวิเคราะห์กำไร",
          "5zmqie08yzo0": "จำนวนเงินชำระจากแพลตฟอร์ม",
          "5zmqie08z2o0": "เดือนต่อเดือน",
          "5zmqie08z5c0": "ค่าบรรจุภัณฑ์",
          "5zmqie08z7w0": "การชำระเงินจริงๆ โดยผู้ซื้อ",
          "5zmqie08zak0": "กำไร",
          "5zmqie08zd40": "ค่าสินค้า",
          "5zmqie08zfo0": "อัตรากำไรต่อต้นทุน",
          "5zmqie08zig0": "ค่าใช้จ่ายอื่นๆ",
          "5zmqie08zks0": "อัตรากำไรจากการขาย"
        },
        "index": {
          "5zmr3g7jskw0": "ภาพรวมกำไร"
        }
      },
      "saleDetail": {
        "index": {
          "5zmr52c50ng0": "ภาพรวมข้อมูล",
          "5zmr52c517g0": "รายละเอียดสถิติปริมาณขายสินค้า",
          "5zmr52c51bk0": "กราฟแนวโน้มตัวชี้ข้อมูล",
          "5zmvji9oxrk0": "รหัสสินค้า",
          "5zmvji9oz0w0": "SKU ของแพลตฟอร์ม",
          "5zmvji9oz700": "แพลตฟอร์ม",
          "5zmvji9ozao0": "ร้าน",
          "5zmvjnye99g0": "มิติสินค้า",
          "5zmvlw6cgzg0": "ข้อกำหนด",
          "603i2ug6f3k0": "เวลาเริ่มต้น",
          "603i2ug6gbs0": "เวลาสิ้นสุด",
          "603i2ug6gjc0": "Only data from the past 6 months is supported for querying. Please select a different time frame for your query."
        }
      },
      "salesStatistics": {
        "constant": {
          "5zmqf0amgts0": "ตาม SPU (สินค้า)",
          "5zmqf0ami0c0": "ตาม SKU (สินค้าเดี่ยว)",
          "5zmqf0ami6o0": "ตามร้าน",
          "5zmqf0amib40": "ช่วงราคา",
          "5zmqf0amifc0": "ช่วงการขาย",
          "5zmqf0amilc0": "สินค้าทั้งหมด",
          "5zmqf0amiqg0": "จำนวนคำสั่งซื้อที่ชำระเงิน",
          "5zmqf0amiuo0": "ปริมาณขาย",
          "5zmqf0amj000": "จำนวนเงินทั้งหมดที่ชำระ",
          "5zmqf0amj440": "จำนวนคำสั่งซื้อคืนเงิน",
          "5zmqf0amj8c0": "จำนวนเงินคืน",
          "5zmqf0amjcw0": "ชื่อสินค้า",
          "5zmqf0amjhc0": "ID สินค้า",
          "5zmqf0amjkw0": "SKU ของแพลตฟอร์ม"
        },
        "hooks": {
          "5zmr1xvtlyw0": "ตัวกรองวันที่"
        },
        "index": {
          "5zmr18mjbic0": "สถิติยอดขาย"
        }
      },
      "shopStatistics": {
        "components": {
          "60a9ixf7pqs0": "รายละเอียดร้านค้า",
          "60a9ixf7q7w0": "ร้านค้า:",
          "60a9ixf7qcg0": "จำนวนลูกค้า:",
          "60a9ixf7qfc0": "จำนวนคำสั่งซื้อ:",
          "60aacanra200": "สถิติข้อมูล"
        },
        "constant": {
          "60a9gbqw2w80": "จำนวนลูกค้า",
          "60a9gbqw3os0": "จำนวนคำสั่งซื้อ",
          "60a9gbqw3sk0": "ยอดรวมคำสั่งซื้อ",
          "60a9gbqw3v80": "จำนวนเงินทั้งหมดที่ชำระ",
          "60a9gbqw3xg0": "จำนวนเงินคืน",
          "60a9gbqw3zw0": "ค่าเฉลี่ยต่อการสั่งซื้อ",
          "60a9gbqw42c0": "ปริมาณคำสั่งชำระเงิน",
          "60a9gbqw4d40": "จำนวนลูกค้าที่ thanh toán",
          "60a9gbqw4i40": "จำนวนคำสั่งซื้อคืนเงิน",
          "60a9gbqw4po0": "จำนวนลูกค้าขอคืนเงิน"
        }
      }
    },
    "warehouse": {
      "baseIndex": {
        "5wfvehrixdc0": "พัสดุเข้าโกดังวันนี้",
        "5wfvehrixtk0": "SKU วางจำหน่ายแล้ววันนี้",
        "5wfvehrj1ts0": "โปรดเลือกคลังสินค้า",
        "5wfvehrj1zs0": "ลงชื่อเข้าใช้",
        "5wfvehrj24w0": "การจัดเก็บด้วยมือ",
        "5wfvehrj29k0": "ออกจากคลังสินค้าด้วยตนเอง",
        "5wfvehrj2ig0": "หยิบสินค้าชิ้นเดียว",
        "5wfvehrj2ng0": "สอบถามสินค้า",
        "5wfvehriixa0": "คลังสินค้า",
        "5wfvehrj2na0": "การสแกนการจัดส่ง",
        "619ereolw8g0": "การคืนสินค้าเข้า"
      },
      "components": {
        "packageItem": {
          "5rnxjrj9pt00": "กรุณากรอก/สแกนหมายเลขติดตามการขนส่งและหมายเลขใบสั่งซื้อ",
          "5rnxl2ugdn80": "ประเภทที่จะจัดเก็บ",
          "5rnxl2uge480": "จำนวนการจัดเก็บ",
          "5rnxl2ugey80": "หมายเหตุ"
        }
      },
      "goodsSearch": {
        "baseIndex": {
          "5weqyxxhmtg0": "สอบถามสินค้า",
          "5weqyxxhnfg0": "สแกนสินค้า sku/ รหัสสินค้า",
          "5weqyxxhnjw0": "กรุณาสแกนข้อมูลสินค้า",
          "5weqyxxhnms0": "กรุณาป้อน sku/ รหัสสินค้า",
          "5weqyxxhnpk0": "กำลังโหลด",
          "5weqyxxhnsk0": "ไม่พบสินค้านี้"
        },
        "components": {
          "GoodsCard": {
            "GoodsInfo": {
              "5wer21zf7rk0": "รหัสสินค้า",
              "5wer21zf8jg0": "ยอดขายเกือบ7/30/60วัน"
            },
            "index": {
              "5wer2vtlaf40": "สินค้าย่อย"
            },
            "StorageInfo": {
              "5wer3yv4lx40": "ตำแหน่ง",
              "5wer3yv4mgo0": "สินค้าคงคลังทั้งหมด",
              "5wer3yv4o8g0": "มีจำหน่าย",
              "5wer3yv4ofw0": "ครอบครองล่วงหน้า"
            }
          }
        }
      },
      "processStorage": {
        "baseIndex": {
          "5rny2tblyc00": "สแกนสินค้า",
          "5rny2tblz7g0": "กรุณาใส่/สแกน SKU สินค้ารหัสสินค้า",
          "5rny2tblzds0": "สิ้นสุดการจัดเก็บนี้",
          "5rny2tblzkw0": "ออกจากคลังสินค้า",
          "5rny2tblzps0": "การจัดเก็บ",
          "5rny2tblztk0": "ไม่พบสินค้า",
          "5rny2tblzx00": "ไม่สามารถจัดเก็บผลิตภัณฑ์รวมได้",
          "5rny2tbm00g0": "กำลังโหลด",
          "5rny3yibbno0": "ประสบความสำเร็จ",
          "5rny51km7e40": "จำนวนขาออกต้องไม่เกิน",
          "5rolykv54d00": "กรุณาป้อนสินค้า SKU"
        },
        "components": {
          "GoodsPopup": {
            "5rnxodldrdc0": "ชนิด",
            "5rnxodlds640": "ชิ้น",
            "5rnxodldsdc0": "ส่ง"
          },
          "SetQuantityDialog": {
            "5rnxpb2qwsg0": "กรุณาใส่จำนวน",
            "5rnxu9u7r2c0": "ในครั้งนี้",
            "5ron09jvyh80": "จำนวน",
            "5rnxpb2qxg00": "ปริมาณต้องไม่ว่างเปล่า",
            "5rnxu9u7q4w0": "จำนวนต้องไม่เกิน"
          }
        },
        "processGoodsWithStoragePage": {
          "baseIndex": {
            "5rnxzgpf7i00": "สินค้า",
            "5rnxzgpf8co0": "ชื่อ:",
            "5rnxzgpf8io0": "ข้อมูลจำเพาะ:",
            "5rnxzgpf8mg0": "จำนวนที่จะจัดเก็บ:",
            "5rnxzgpf8q40": "ในครั้งนี้",
            "5rnxzgpf8tk0": "ไม่มีตำแหน่ง",
            "5rnxzgpf8ww0": "ไม่ได้เลือกตำแหน่ง",
            "5rnxzgpf90g0": "ลบ",
            "5rnxzgpf93s0": "เปลี่ยนตำแหน่ง",
            "5rnxzgpf97c0": "ออกจากคลังสินค้า",
            "5rnxzgpf9ao0": "การจัดเก็บ",
            "5rnxzgpf9e80": "ตำแหน่งต้องไม่ว่างเปล่าโปรดตรวจสอบตำแหน่ง",
            "5rnxzgpf9hs0": "กำลังดำเนินการอยู่",
            "5rnxzgpf9l80": "ข้อมูลผิดปกติโปรดติดต่อฝ่ายบริการลูกค้า",
            "5rny0rcmwbk0": "เป็น",
            "5werec5q0lc0": "กำหนด"
          }
        },
        "scanStorageCellPage": {
          "baseIndex": {
            "5rny1og9cek0": "ตำแหน่งการสแกน",
            "5rny1og9d740": "กรุณาสแกน/ป้อนหมายเลขตำแหน่ง",
            "5rny1og9ddc0": "กรุณาสแกนตำแหน่ง",
            "5rny1og9dgw0": "กำลังสอบถาม",
            "5roninktevk0": "สินค้าไม่มีในสต็อกในตำแหน่งนี้"
          }
        }
      },
      "putaway": {
        "baseIndex": {
          "5rnyj390bf00": "สแกนพัสดุ",
          "5rnyj390c1o0": "กรุณากรอก/สแกนหมายเลขติดตามการขนส่งและหมายเลขใบสั่งซื้อ",
          "5rnxm6vsad80": "กรุณาสแกนพัสดุ",
          "5rnyj390c7g0": "รอการลงนามในใบสั่งซื้อ",
          "5rnyj390cb40": "กำลังโหลด",
          "5rnyj390ce80": "ไม่มีข้อมูล",
          "5rnyj390cho0": "เซ็นใบสั่งซื้อเรียบร้อยแล้ว"
        },
        "components": {
          "WaitReceivePurchaseOrderList": {
            "5rnyh09ydbg0": "ไม่มีข้อมูล"
          }
        },
        "purchaseOrderInfoPage": {
          "baseIndex": {
            "5rnyibnc0h40": "สแกนพัสดุ",
            "5rnyibnc14g0": "ชื่อ:",
            "5rnyibnc1a00": "ข้อมูลจำเพาะ:",
            "5rnyibnc1dw0": "รหัส:",
            "5rnyibnc1hc0": "จบแล้ว",
            "5rnyibnc1l00": "คลังสินค้าโดยตรง",
            "5rnyibnc1ow0": "สแกนการจัดเก็บสินค้า",
            "5rnyibnc1sg0": "หลังจากการจัดเก็บโดยตรงสินค้าคงคลังจะเพิ่มขึ้นยืนยันการจัดเก็บ?",
            "5rnyibnc1w80": "ในคลังสินค้า..."
          }
        }
      },
      "scanReturn": {
        "components": {
          "WaitReturnGoodsList": {
            "619evhn1suw0": "ประเภทที่จะจัดเก็บ",
            "619evhn1txg0": "จำนวนการจัดเก็บ",
            "619evhn1u580": "ไม่มีข้อมูล"
          }
        },
        "hooks": {
          "useReturnGoods": {
            "619eub7jj1s0": "ในคลังสินค้า...",
            "61wzjfwnawk0": "ปริมาณสินค้าขาเข้าต้องไม่เกิน {0}"
          },
          "useScan": {
            "619euwhlq5o0": "กำลังโหลด",
            "619euwhlqyg0": "ไม่มีข้อมูล"
          }
        },
        "returnOrderInfoPage": {
          "index": {
            "619etj4urs80": "การคืนสินค้าเข้า",
            "619etj4usrk0": "ชื่อ:",
            "619etj4usxs0": "ข้อมูลจำเพาะ:",
            "619etj4ut1s0": "จบแล้ว",
            "619etj4ut540": "คลังสินค้าโดยตรง",
            "619etj4ut980": "สแกนการจัดเก็บสินค้า",
            "619etj4uteg0": "หลังจากการจัดเก็บโดยตรงสินค้าคงคลังจะเพิ่มขึ้นยืนยันการจัดเก็บ?"
          }
        },
        "constant": {
          "619espdpd100": "สแกนพัสดุ",
          "619espdpe9k0": "ออร์เดอร์คืนสินค้ารอเข้าคลังสินค้า"
        },
        "index": {
          "619es2vhols0": "กรุณาสแกนพัสดุ",
          "619es2vho3o0": "การคืนสินค้าเข้า",
          "619es2vhoi80": "โปรดป้อน/สแกนหมายเลขใบส่งของ หมายเลขออร์เดอร์ หรือหมายเลขพัสดุ",
          "619es2vhooo0": "ออร์เดอร์คืนสินค้าเข้าคลังสินค้าสำเร็จ"
        }
      },
      "scanShipment": {
        "components": {
          "List": {
            "5wsld4f61gw0": "ไม่มีข้อมูล"
          },
          "OrderPackageItemMedia": {
            "5wsld4f61gw0": "1.รูปภาพ/วิดีโอที่อัปโหลดสามารถดูได้ในพื้นที่รูปภาพ",
            "5wsld4f61gw1": "2.วิดีโอต้องไม่เกิน30M และความยาวต้องไม่เกิน1นาที",
            "5wsld4f61gw2": "อัปโหลดรูปภาพ",
            "5wsld4f61gw3": "อัปโหลดวิดีโอ",
            "5wsld4f61gw4": "เลือกวิดีโอ",
            "5wsld4f61gw5": "รูปภาพ",
            "5wsld4f61gw6": "วิดีโอ",
            "5wsld4f61gw7": "ระยะเวลาไม่เกิน1นาที"
          },
          "PackageItemGoods": {
            "5wsldq8io7w0": "ข้อมูลจำเพาะ"
          },
          "ShipmentButton": {
            "5wsle2o8bvc0": "จัดส่งสินค้า"
          }
        },
        "hooks": {
          "useList": {
            "5wslbuh0fts0": "พัสดุถูกสแกนในครั้งนี้",
            "5wslbuh0j1c0": "กำลังโหลด",
            "5wslbuh0j8g0": "ไม่มีข้อมูล"
          },
          "useShipmentPackage": {
            "5wslcgxgpio0": "ในการดำเนินการ",
            "5wslcgxgu5g0": "การจัดส่งล้มเหลว",
            "5wslcgxgug00": "จัดส่งเรียบร้อยแล้ว"
          }
        },
        "index": {
          "5wsl89cm3gw0": "การสแกนการจัดส่ง",
          "5wsl89cm7z80": "สแกนหรือป้อนหมายเลขพัสดุ/หมายเลขใบนำส่งสินค้า",
          "5wsl89cm8980": "สแกนแล้ว",
          "5wsl89cm8ds0": "กรุณาสแกนข้อมูลพัสดุ",
          "5wsl89cm8hc0": "กำลังโหลด",
          "5wsl89cm8hc1": "จัดส่งอัตโนมัติหลังจากการสแกน"
        },
        "scannedListPage": {
          "index": {
            "5wslasiw3rc0": "สแกนแล้ว",
            "5wslasiw8r40": "แน่ใจหรือว่าต้องการนำพัสดุออก?",
            "5wslasiw9200": "เมื่อกลับมารายการพัสดุที่สแกนจะถูกล้างและแน่ใจว่าจะดำเนินการต่อ?",
            "5wslasiw9940": "ทั้งหมด",
            "5wslasiw9f40": "ไม่สามารถจัดส่งพัสดุได้"
          }
        }
      },
      "singlePick": {
        "baseIndex": {
          "5wesij4wgig0": "หยิบสินค้าชิ้นเดียว",
          "5wesij4whps0": "สแกน/ป้อนหมายเลขคลื่น",
          "5wesij4whv40": "แน่ใจว่าคุณต้องการทำเครื่องหมายสถานะเสร็จสมบูรณ์หรือไม่?",
          "5wesij4whyk0": "เพื่อหลีกเลี่ยงการหยิบสินค้าซ้ำขอแนะนำให้ยืนยันหรือตรวจสอบบันทึกการดำเนินการกับผู้ใช้บัญชีก่อนที่จะเริ่มหยิบสินค้า",
          "5wesij4wi1s0": "หลังจากทำเครื่องหมายแล้วคลื่น {0} จะไม่สามารถหยิบสินค้าได้",
          "5wesij4wi5s0": "เลขที่บัญชี:{0} ดำเนินการหยิบสินค้าที่ {1}",
          "5wesij4wi0s0": "และคลื่นจะเข้าสู่สถานะที่จะบรรจุ"
        },
        "components": {
          "WaveList": {
            "WaveItem": {
              "5wesqu5fcgs0": "เลือกแล้ว/จะเลือก",
              "5wesqu5fi8s0": "ข้อมูลสินค้า",
              "5wesqu5fihk0": "สร้างเวลา",
              "5wesqu5fimg0": "ผู้สร้าง",
              "5wesqu5fiv00": "มากกว่า",
              "5wesqu5fizs0": "เริ่มหยิบสินค้า",
              "5wesqu5fj3w0": "ทำเครื่องหมายเสร็จแล้ว",
              "5wesqu5fj800": "บันทึกการหยิบสินค้า",
              "5wesqu5fjck0": "{0} ชนิดของ SKU รวม {1}"
            }
          }
        },
        "hooks": {
          "useOperate": {
            "5wespf869ys0": "กำลังโหลด",
            "5wespf86efs0": "หยิบสินค้าต่อไป",
            "5wespf86et80": "บันทึกการหยิบสินค้า",
            "5wespf86eys0": "สถานะคลื่นได้รับการอัปเดตแล้วโปรดเลื่อนลงเพื่อรีเฟรชหน้าเพื่อดู"
          }
        },
        "subPages": {
          "pickLog": {
            "baseIndex": {
              "5weyeh0dlbs0": "บันทึกการหยิบสินค้า"
            },
            "components": {
              "LogItem": {
                "5weygrs8su40": "สินค้า SKU",
                "5weygrs8xvc0": "ปริมาณการหยิบ"
              }
            }
          },
          "picking": {
            "baseIndex": {
              "5wey0wt55a40": "สแกน/ป้อน SKU, รหัสสินค้า",
              "5wey0wt64ns0": "ชนิด SKU,",
              "5wey0wt650g0": "รวม",
              "5wey0wt65b00": "หนึ่ง",
              "5wey1e5fupo0": "เลือกที่เหลือ:"
            },
            "components": {
              "SetPickQuantityDialog": {
                "5wey82q92us0": "กรอกจำนวนที่เลือก",
                "5wey82q99rg0": "กรุณาใส่",
                "5wey82q9a100": "กรุณากรอกจำนวนที่เลือก",
                "5wey82q9a1g0": "ปริมาณที่เลือกต้องไม่เกินปริมาณที่จะเลือก:{0}"
              },
              "SkuItem": {
                "5wey9z9uhxs0": "รหัสสินค้า",
                "5wey9z9uoe00": "ตำแหน่ง",
                "5wey9z9uook0": "เลือกแล้ว",
                "5wey9z9uoyw0": "ที่จะเลือก"
              },
              "WavePickFinishDialog": {
                "5weycylwol80": "เคล็ดลับ",
                "5weycylwsss0": "ฉันรู้แล้ว",
                "5weycylwt1c0": "การหยิบคลื่นปัจจุบันเสร็จสมบูรณ์แล้ว!"
              }
            },
            "hooks": {
              "usePicking": {
                "5wey5ujb4xs0": "คลื่นปัจจุบันไม่มีสินค้านี้",
                "5wey5ujbaeo0": "สินค้า SKU:{0} เลือกแล้ว"
              }
            }
          }
        }
      }
    }
  },
  "system": {
    "confirm": "กำหนด",
    "cancel": "ยกเลิก",
    "pleaseSelect": "โปรดเลือก",
    "5rnxe856we80": "เคล็ดลับ",
    "5rnxe856wl00": "ยืนยัน",
    "loading": "กำลังโหลด",
    "copySuccess": "คัดลอกสำเร็จ",
    "emptyShelfCell": "ไม่มีตำแหน่ง",
    "versionInfo": "ข้อมูลเวอร์ชัน",
    "language": {
      "toggle": "สลับภาษา",
      "selectEmpty": "โปรดเลือกภาษา"
    },
    "tabBar": {
      "stat": "ข้อมูล",
      "home": "คลังสินค้า",
      "my": "ของฉัน"
    },
    "update": {
      "title": "เคล็ดลับการอัปเดต",
      "forceUpdate": "พบเวอร์ชันใหม่รีบูตทันที",
      "fail": "การปรับปรุงล้มเหลว",
      "newVersion": "ค้นพบเวอร์ชันใหม่",
      "updateNow": "อัปเดตทันที",
      "updateLater": "การอัปเดตครั้งต่อไป"
    }
  }
}