export default {
  "components": {
    "Account": {
      "UnReceivedCodeLink": {
        "5xz3hmxq1380": "No Verification Code Received?",
        "5xz3hmxq4ws0": "Close",
        "5xz3hmxq5800": "May Be Identified as Spam, Please Check in Spam",
        "5xz3hmxq5fc0": "Check Whether the Email Address You Entered Is Correct Or Try to Register with Another Email Address.",
        "5xz3hmxq5lo0": "Check Whether the Current Network Problem Is Normal.",
        "5xz3hmxq5s40": "No Verification Code Received?"
      }
    },
    "Empty": {
      "5weyj6xvl9o0": "No Data Available"
    },
    "ProComponent": {
      "ProConfirmPopup": {
        "5weyne0t7t40": "Prompt",
        "5weyne0tctw0": "OK",
        "5weyne0td2o0": "Cancel"
      }
    },
    "ScanCodeView": {
      "ScanCodeView": {
        "5weysudo0zk0": "the Express Number Has Illegal Characters. Please Scan It Again."
      },
      "ScanCameraApp": {
        "5weas1do0zk0": "Getting Camera Information"
      }
    },
    "SearchBackBar": {
      "5weyueldnb00": "Filter"
    },
    "SelectView": {
      "5weyy43t5v00": "Enter a Search Keyword",
      "5weyy43tasw0": "No Data Available"
    },
    "ShopTag": {
      "60aatigyeig0": "Cross-border"
    },
    "Warehouse": {
      "ScanModeSelect": {
        "5wez10tdkk80": "Select a Scan Method",
        "5wez10tdpow0": "Laser Scanning",
        "5wez10tdpxg0": "Camera Scan"
      },
      "StockModeSelect": {
        "5wez1zd63kw0": "Select a Warehousing Method",
        "5wez1zd68ag0": "Please Select the Outbound Method"
      },
      "WarehouseSelect": {
        "5wez1mdf9v80": "Select a Repository",
        "5wez1mdfep80": "No Warehouse Permission, Please Contact the Primary Account to Add"
      }
    }
  },
  "constants": {
    "package": {
      "5wskfpirawg0": "Pending Submission",
      "5wskfpirer00": "Failed",
      "5wskfpirey40": "Success"
    },
    "platform": {
      "5zwog8ot6ow0": "手工订单",
      "5zwog8ot8zs0": "零售订单",
      "602uc1886b40": "Lazada JIT"
    },
    "shipment": {
      "5wsjxwq908g0": "Scan Once",
      "5wsjxwq96nc0": "Scan and Search for Parcels and Submit Shipments",
      "5wsjxwq970k0": "Scan Twice",
      "5wsjxwq97880": "after Scanning, Search for the Package First and Scan Again to Automatically Deliver the Goods.",
      "5wsjxwq97ek0": "Manual",
      "5wsjxwq97lo0": "Automatic",
      "5wsjxwq97s80": "Ignore",
      "5wsjxwq97yo0": "Mandatory",
      "5yjh2ncp5go0": "Enable",
      "5yjh2ncp6p80": "Close"
    }
  },
  "pages": {
    "account": {
      "components": {
        "TwoFactorAuthenticationPopup": {
          "60ae807iv8k0": "Login Verification",
          "60ae807ivnc0": "",
          "60ae807ivq00": "Insecure login location",
          "60ae807ivq01": "",
          "60ae807ivrw0": "To ensure account security, please  verify before logging in.",
          "60ae807ivtg0": "Send Code",
          "60ae807ivvs0": "Verify now",
          "60ae807ivys0": "Email Code",
          "60ae807iw0s0": "SMS Code",
          "60ae807iw3o0": "Please enter the verification code!",
          "60ae807iw5w0": "Obtaining Verification Code",
          "60ae807iw7s0": "Verification code sent successfully",
          "60ae8fkmn680": "Resend{0}",
          "60gic918gtg0": "Verification successful",
          "60gicqrsmqk0": ""
        }
      },
      "currencyAndTimeZone": {
        "currencyAndTimeZone": {
          "index": {
            "60wmecfnlrg0": "Currency",
            "60wmecfnmfo0": "Select a Currency",
            "60wmecfnmfo2": "The Cost, Profit, and Other Amounts of Goods in the System Will Be Displayed in {0}. after Selection, Modification Is Not Supported.",
            "60wmecfnmjs0": "Time Zone",
            "60wmecfnmms0": "Select a Time Zone",
            "60wmecfnmpo0": "Save",
            "60wmecfnms40": "Prompt",
            "60wmecfnmuo0": "After You Select a Currency, You Cannot Modify It Again. Are You Sure You Want to Select the Current Currency?",
            "60wmecfnmws0": "Saved Successfully",
            "60wmecfnlrg4": "Select Currency and Time Zone"
          }
        }
      },
      "forget-password": {
        "index": {
          "5weq2lrg6780": "Mobile Phone Number",
          "5weq2lrg7a40": "Please Enter Your Mobile Phone Number",
          "5weq2lrg7fg0": "New Password",
          "5weq2lrg7io0": "Enter a Password",
          "5weq2lrg7lk0": "the Password Must Be at Least 8 Characters, Including Characters, Numbers, and Special Symbols.",
          "5weq2lrg7os0": "Confirm Password",
          "5weq2lrg7s00": "Verification Code",
          "5weq2lrg7uw0": "Please Enter a Verification Code",
          "5weq2lrg8580": "Reset Password",
          "5weq2lrg88k0": "the Phone Number Format Is Incorrect.",
          "5weq2lrg8bg0": "the Passwords Entered Twice Are Inconsistent.",
          "5weq2lrg8eg0": "Obtaining Verification Code",
          "5weq2lrg8h40": "Verification Code Sent",
          "5weq2lrg8jw0": "Modified Successfully"
        },
        "page": {
          "forgetPassword": "Forgot Password"
        }
      },
      "login": {
        "5rnx1lpiie80": "Account",
        "5rnx1lpiwp40": "Password",
        "5rnx1lpiwz40": "Login",
        "5rnx1lpix540": "Forgot Password",
        "5rnx1lpix9s0": "Register an Account",
        "5rnx1lpixes0": "Please enter email/phone number/sub-account",
        "5rnx1lpixko0": "Email/Phone number/Sub-account required",
        "5rnx1lpixps0": "the Account Does Not Exist. Please Enter It Again.",
        "5rnx1lpixus0": "Password Required",
        "5rnx1lpiy5g0": "the Password Is Incorrect. Please Enter It Again.",
        "5rnx1lpiyk00": "the Account Does Not Exist.",
        "5rnx1lpiyqc0": "Incorrect Password",
        "5rnx1lpiyv00": "Send Code",
        "5rnx1lpiyyc0": "Second Reacquisition",
        "5rnx1lpiz2g0": "Retrieve Again"
      },
      "register": {
        "register": {
          "placeholder": {
            "email": "Please Enter an Email Address",
            "password": "Enter a Password",
            "password2": "Confirm the Password Again",
            "mobile": "Please Enter Your Mobile Phone Number",
            "emailValidateCode": "Enter Email Verification Code",
            "mobileValidateCode": "Please enter the mobile verification code"
          },
          "getValidateCodeBtn": "Send Code",
          "agreeCheckTipsBefore": "I Have Read and Agree",
          "agreeCheckTipsAfter": {
            "userAgreement": "User Service Agreement,",
            "privacyAgreement": "Privacy Agreement"
          },
          "confirm": "Free Registration",
          "success": {
            "message": "Registered Successfully",
            "tips": "Follow These Steps to Log in and Use Easyboss",
            "copyLink": "Copy Link",
            "jumpToPc": "Enter the Computer Software Interface"
          },
          "validatorError": {
            "password": "the Password Must Be at Least 8 Characters, Including Characters, Numbers, and Special Symbols.",
            "email": "Incorrect Mailbox Format",
            "mobile": "the Phone Number Format Is Incorrect.",
            "checkedAgree": "Select a Protocol",
            "passwordNotSame": "the Passwords Entered Twice Are Inconsistent."
          },
          "gettingValidateCode": "Obtaining Verification Code",
          "validateCodeSendSuccess": "Verification Code Sent",
          "mobileCountryCode": "Please Select Area Code",
          "5weqrdhch7o0": "Register",
          "5weqrdhci4w0": "the Password Must Be at Least 8 Characters, Including Characters, Numbers, and Special Symbols.",
          "5weqrdhci9k0": "Confirm Password",
          "5weqrdhcicw0": "Fuzhou Jianqiyun Network Technology Co., Ltd.",
          "5weqrdhcijs0": "Copyright",
          "5weqrdhcimw0": "Fujian Icp Standby No. 2020020145-5",
          "index": {
            "61f8z24qhjg0": "The graphic verification code is incorrect"
          }
        },
        "copy": {
          "success": "Copied Successfully"
        },
        "page": {
          "register": "Register"
        },
        "greatboss": {
          "register": {
            "success": {
              "tips": "Follow These Steps to Log in and Use Greatboss"
            }
          }
        }
      }
    },
    "distribution": {
      "bind": {
        "protocol": {
          "index": {
            "60j4x24i71g0": "Easyboss Distribution Cooperation Notice",
            "60j4x24i71g2": "Dear Users, Welcome to Use Easyboss Distribution Cooperation Function! Before Using the Easyboss Distribution Cooperation Function, You Should Carefully Read and Abide by the Easyboss-Distribution Cooperation Instructions.",
            "60j4x24i71g3": "1. Suppliers and Distributors Can Carry out Distribution Cooperation Business through Easyboss Distribution Tools. Both Parties Cooperate on a Voluntary and Independent Basis. for Related Issues Such as Distribution, Orders, after-Sales, Funds and So on Arising from the Cooperation, please Provide a Friendly Settlement between the Distribution Parties. Easyboss Does Not Provide Any Guarantee and Has No Obligation to Cooperate with Both Parties.",
            "60j4x24i71g4": "2. The Product Information Uploaded and Edited in Easyboss (Including but Not Limited to the Supply Price, Product Description and Description, Relevant Pictures, Videos, Etc.) Shall Be Provided by the Supplier, the Supplier Shall Guarantee the Legality of the Commodity Information and Its Own Behavior and Shall Not Infringe upon the Rights and Interests of Any Third Party. All Consequences Arising from the above-Mentioned Behavior of the Supplier Shall Be Borne by the Supplier Itself, and Easyboss Reserves the Relevant Right of Prosecution.",
            "60j4x24i71g5": "3. If You Use the \"Distribution Fund\" Function, Both Parties Shall Pay Offline Money; Easyboss Only Provides the Bookkeeping Function, and Does Not Provide Clearing, Splitting and Other Fund-Related Services for Both Parties, nor Be Responsible for Any Disputes Arising Therefrom.",
            "60j4x24i71g6": "4. The Distributor Pushes the Order to the Supplier for Processing. Due to Any Reason, the Supplier Delays the Delivery and Cancellation of the Order. The Impact Will Be Solved by Both Distributors, easyboss Does Not Provide Any Guarantee and Has No Obligation to Cooperate with Both Parties.",
            "60j4x24i71g8": "Distribution Invitation"
          }
        },
        "bind": {
          "index": {
            "60j51capb2s0": "Supplier {0} Invites You to Establish Cooperation with Him. Please Choose Whether to Accept the Cooperation Invitation.",
            "60j51capb6s0": "After Accepting the Invitation, Please Wait Patiently for the Supplier to Review. after the Review Is Passed, the Cooperation Can Be Displayed.",
            "60j51capb9g0": "You Can Fill in the Application Information to Quickly Pass Your Application.",
            "60j51capbcg0": "I Have Read and Agree",
            "60j51capbfk0": "Not Accepted Yet",
            "60j51capbi40": "Accept",
            "60j51capbks0": "Click Here to Log on",
            "60j51capbps0": "Click",
            "5yg2sdxxi8t0": "Supplier {0} Invites You to Establish Cooperation with Him. Please Log on to Easyboss ERP First.",
            "60j51capbps1": "Loading..."
          }
        },
        "hooks": {
          "useDistributionBind": {
            "5yg2wlqzuo00": "Operation Succeeded",
            "5yg2wlqzus40": "Prompt",
            "5yg2wlqzuus0": "I Already Know",
            "5yg2wlqzuxc0": "The Cooperation Invitation Link Has Expired. Please Contact the Supplier for a New Invitation Cooperation Link.",
            "5yg2wlqzuxa0": "You Have Established Cooperation with Supplier {0}!",
            "5yg2wlqzuxt0": "You Have Initiated a Cooperation Application with Supplier {0}! Please Wait for Supplier Review",
            "5yg2wlqzuxi0": "Supplier {0} Has Terminated Cooperation with You, Please Contact Supplier",
            "5yg2wlqzuxp0": "Supplier {0} Is in an Abnormal State and Cannot Cooperate for the Time Being.",
            "5yg2wlqzuxh0": "The Supplier Has Closed the Distribution Process and Cannot Cooperate.",
            "5yg2wlqzuyr0": "Please Use Your Primary Account to Log on to Establish a Cooperation Relationship.",
            "5yg2wlqzuo01": "Please Read and Agree to the Distribution Cooperation Instructions."
          }
        },
        "DistributionCoopConfirmDialog": {
          "index": {
            "5zqoed6x0k40": "Prompt",
            "5zqoed6x2500": "I Have Read and Agree with {0}",
            "5zqoed6x2ds0": "Please Read and Agree to the Distribution Cooperation Instructions."
          }
        },
        "default": {
          "index": {
            "60j4x24i71g6-1": "The Operation Is Successful. Please Log on to {0} on the PC to View It.",
            "60j4x24i71g6-2": "Copy Link",
            "60j4x24i71g6-3": "Copied Successfully"
          }
        }
      },
      "constant": {
        "constant": {
          "distribution": {
            "5yg7j4qkamo0": "in Cooperation",
            "5yg7j4qkb0o0": "Supplier Terminated",
            "5yg7j4qkb3g0": "Distributor Terminated",
            "5yg7j4qkb6c0": "Supplier Rejected",
            "5yg7j4qkb800": "Distributor Rejected",
            "5yg7j4qkb9s0": "to Be Authorized by the Supplier",
            "5yg7j4qkbco0": "To Be Accepted by Distributors",
            "5yg7j4qkbe80": "to Be Authorized",
            "5yg7j4qkbfs0": "Rejected",
            "5yg7j4qkbhg0": "Terminated",
            "5yg7j4qkbht0": "Abnormal Status"
          }
        }
      }
    },
    "my": {
      "my": {
        "components": {
          "InputAutoFocusSettingSelect": {
            "5yjh4axepj00": "Automatic Focus of Input Box"
          },
          "ScanShipmentSettingSelect": {
            "5wsklmc59bk0": "Scan Delivery"
          }
        }
      },
      "myIndex": {
        "5rnxe856up00": "Personal Center",
        "5rnxe856vpg0": "Freight Forwarding Version",
        "5rnxe856vw00": "Free Edition",
        "5rnxe856vzo0": "Paid Version",
        "5rnxe856w380": "Warehouse",
        "5rnxe856w780": "Scanning Mode",
        "5rnxe856wao0": "Log out",
        "5rnxe856whs0": "Are You Sure You Want to Log out?",
        "5rnxe856wq40": "Language",
        "5rnxe856wv40": "to Switch Languages, Restart the Application.",
        "5rnxe856wha0": "Scan Shipping Settings",
        "5rnxe856whb0": "Loading",
        "5rnxe856whb1": "Please Do Not Press the Laser Scan Button Again When Using the Camera to Scan, the Device May Get Stuck",
        "5rnxe856whb2": "Automatic Focus of Input Box"
      }
    },
    "permission": {
      "404": {
        "60cyyf5ngl80": "No permission",
        "60cyyf5nhjo0": "No permission, please contact the main account to add"
      }
    },
    "stat": {
      "HotGoodsSection": {
        "constant": {
          "5zmqkoscpn00": "By SKU (Single item)",
          "5zmqkoscqlo0": "By SPU (Product)"
        },
        "index": {
          "5zmr5k20gnk0": "Top 10 best-selling products",
          "5zmr5k20i840": "Specifications",
          "5zmr5k20ifs0": "Platform SKU",
          "5zmr5k20ikg0": "Product ID",
          "5zmr5k20iok0": "Main product number",
          "5zmr5k20it00": "Store",
          "5zmr5k20ixc0": "Sales amount",
          "5zmr5k20j1g0": "Sales volume"
        }
      },
      "HotPlatformSection": {
        "index": {
          "5zmr5t9re380": "Top 5 best-selling platforms",
          "5zmr5t9rf3o0": "Ranking",
          "5zmr5t9rf7k0": "Platform",
          "5zmr5t9rf9s0": "Sales amount"
        }
      },
      "HotShopSection": {
        "index": {
          "5zmr60rhpes0": "Top 5 best-selling stores",
          "5zmr60rhpyk0": "Ranking",
          "5zmr60rhq100": "Store",
          "5zmr60rhq300": "Platform",
          "5zmr60rhq4k0": "Sales amount"
        }
      },
      "StatShopSelect": {
        "index": {
          "5zmr8nz3oa40": "Select platform",
          "5zmr8nz3pac0": "Select site",
          "5zmr8nz3pio0": "Select store"
        }
      },
      "TotalViewSection": {
        "constant": {
          "5zmqmu4tw1g0": "Total payment amount",
          "5zmqmu4twh40": "Expected actual receipt",
          "5zmqmu4twks0": "Number of paying customers",
          "5zmqmu4twns0": "Compared to yesterday",
          "5zmqmu4twq80": "Payment order quantity",
          "5zmqmu4twuk0": "Number of refund customers",
          "5zmqmu4twx00": "Number of refund orders",
          "5zmqmu4twzg0": "Total refund amount",
          "5zmqmu4tx3o0": "Estimated profit",
          "5zmqmu4tx6g0": "Profit margin"
        },
        "index": {
          "5zmr673frbk0": "Data for the day, updated every 2 hours"
        }
      },
      "common": {
        "60a99nlkohc0": "Profit details",
        "60a9dq4u54g0": "Product sales",
        "60a9d45degg0": "Product statistics",
        "60a9eurfpww0": "Packaging material statistics",
        "60a9feawjog0": "Shop statistics",
        "60a9ht0fpgc0": "Date filter",
        "60a9lb8cd0w0": "Currency unit",
        "60a9lb8cdko0": "By order status",
        "60a9rrnp5rs0": "Data statistics",
        "5saylxfm3ps0": "The Maximum Interval Is 3 Months, Please Reselect The Time."
      },
      "components": {
        "CoreIndicator": {
          "5zmr3p5s6280": "Core indicators",
          "5zmr3p5s6s40": "Sales amount"
        },
        "CoreIndicatorChart": {
          "5zmr3x26fdo0": "Trend of core indicators",
          "5zmr3x26g500": "Product statistics",
          "5zmr3x26g8g0": "Percentage (%)"
        },
        "DataStatisticsSection": {
          "5zmr2qhmwkw0": "Data statistics"
        },
        "DisbursementChartCard": {
          "5zmr43sgo9o0": "Expense analysis",
          "5zmr43sgomg0": "{0} percentage: {1}%",
          "5zmr43sgopc0": "Total expenses"
        },
        "GoodsItemSection": {
          "5zmr895tvq80": "Product Sku",
          "60kms1u2s700": "specification"
        },
        "GoodsSection": {
          "5zmr2yq2c0k0": "Product ID",
          "5zmr2yq2cek0": "Main item number",
          "5zmr2yq2ch80": "Platform",
          "5zmr2yq2cj00": "Shop",
          "5zmr2yq2ckk0": "Payment order quantity",
          "5zmr2yq2cm80": "Sales volume",
          "5zmr2yq2cns0": "Total payment amount",
          "5zmr2yq2cpc0": "Total refund amount",
          "5zmr2yq2cr00": "Specifications",
          "5zmr2yq2css0": "Platform SKU"
        },
        "OrderDistributionSection": {
          "5zmr7cert4c0": "Payment order distribution"
        },
        "ProductDistributionSection": {
          "5zmr36t00b80": "Distribution of products with sales"
        },
        "RankingTenList": {
          "5zmr4euc2940": "Top 10 profit analysis",
          "5zmr4euc2z40": "Ranking",
          "5zmr4euc3300": "Profit",
          "5zmr4euc35s0": "Sales amount",
          "5zmr4euc3880": "Sales profit margin",
          "5zmr4euc3bs0": "Total profit"
        },
        "StatDropdown": {
          "5zmr95mf3q00": "Selected",
          "5zwtjniwy940": "Num"
        },
        "StatSearchBar": {
          "5zmr9lrwj5g0": "Please Enter",
          "5zmr9lrwknc0": "Filter"
        },
        "TableSection": {
          "5zmr7j0011s0": "Date",
          "5zmr7j001yw0": "Number of customers",
          "5zmr7j002340": "Number of orders",
          "5zmr7j002680": "Total order amount",
          "5zmr7j0028s0": "Refund amount"
        },
        "StatDetailPopup": {
          "5zmw7ihk8580": "Reset",
          "5zmw7ihk9n80": "OK"
        },
        "QueryBar": {
          "5saylxfm3p80": "The Maximum Interval Is 3 Months, Please Reselect The Time.",
          "5saylxfm4400": "Only Supports Querying Data from The Past 3 Months. Please Reselect The Time"
        },
        "StatRadio": {
          "60aa4y1tqd40": "Selected",
          "60aa4y1tqq80": "Num",
          "60aa4y1tqss0": "All"
        },
        "StatEmpty": {
          "60krhr1rgwk0": "No Data Available"
        }
      },
      "constant": {
        "5zmp81p6xo00": "Payment order quantity",
        "5zmp81p6yuk0": "Total payment amount",
        "5zmp81p6yzs0": "Refund order quantity",
        "5zmp81p6z2w0": "Total refund amount",
        "5zmp81p6z5s0": "Data overview",
        "5zmp81p6z9k0": "Profit overview",
        "5zmp81p6zcg0": "Profit details",
        "5zmp81p6zlk0": "Product statistics",
        "5zmp81p6zug0": "Sales volume statistics",
        "5zmp81p70840": "Order statistics",
        "5zmp81p70fk0": "Shop statistics",
        "5zmp81p70j80": "Product sales",
        "5zmp81p70lg0": "Income and expenditure statistics",
        "5zmp81p70o00": "Packaging material statistics",
        "5zmp81p70q40": "By day",
        "5zmp81p70sc0": "By week",
        "5zmp81p70uk0": "By month",
        "5zmp81p70wg0": "Yesterday",
        "5zmp81p70y80": "Last 7 days",
        "5zmp81p710g0": "Last 30 days",
        "5zmp81p713o0": "Custom date",
        "5zmp81p715c0": "Refund amount",
        "5zmp81p719c0": "Order time",
        "5zmp81p71b00": "Settlement time",
        "5zmp81p71dk0": "Payment time",
        "5zmp81p71f80": "Shipping time",
        "5zmp81p71jg0": "Cut-off for shipping",
        "5zmp81p71ok0": "Sales amount",
        "5zmp81p71rg0": "Estimated repayment amount",
        "5zmp81p71t80": "Actual payment by buyer",
        "5zmp81p71v40": "Product cost",
        "5zmp81p71ws0": "Other costs",
        "5zmp81p71yg0": "Packaging fee",
        "5zmp81p71zs0": "Profit",
        "5zmp81p72180": "Cost profit margin",
        "5zmp81p72340": "Sales profit margin",
        "5zmp81p724w0": "By platform",
        "5zmp81p726c0": "By site",
        "5zmp81p72800": "By store",
        "5zmp81p729c0": "Store",
        "5zmp81p72aw0": "Platform",
        "5zmp81p72f00": "Site",
        "6021uie62280": "Sales volume"
      },
      "hooks": {
        "5zmp74as2c00": "Loading"
      },
      "incomeAndExpenditureStatistics": {
        "constant": {
          "5zmqp7c2t700": "Ending inventory",
          "5zmqp7c2u6c0": "Beginning inventory",
          "5zmqp7c2udo0": "Purchase in",
          "5zmqp7c2uhw0": "Inventory adjustment in",
          "5zmqp7c2ulk0": "Inventory surplus in",
          "5zmqp7c2uq80": "Transfer in",
          "5zmqp7c2uu80": "Return in",
          "5zmqp7c2uyg0": "Manual return in",
          "5zmqp7c2v3o0": "Other in",
          "5zmqp7c2v800": "Sales out",
          "5zmqp7c2vc40": "Inventory adjustment out",
          "5zmqp7c2vgg0": "Inventory loss out",
          "5zmqp7c2vkw0": "Transfer out",
          "5zmqp7c2vp40": "Manual sales out",
          "5zmqp7c2vtk0": "Other out",
          "5zmqp7c2vzw0": "Product Sku",
          "5zmqp7c2w480": "Product name"
        },
        "hooks": {
          "5zmr7yzqthc0": "Date filter",
          "5zmr7yzqtsw0": "Select warehouse"
        },
        "index": {
          "5zmr7qd04is0": "Inbound and outbound statistics"
        }
      },
      "index": {
        "5zmp4zbj9s00": "Real-time data",
        "5zmp4zbjazc0": "Daily data, updated every 2 hours",
        "5zmp4zbjb3k0": "Common functions",
        "5zmvhhwoxlw0": "Currency unit"
      },
      "orderDetail": {
        "constant": {
          "60a9oxflttc0": "Order revenue",
          "60a9oxflu740": "Original order amount",
          "60a9oxflu9o0": "Product price",
          "60a9oxfluc40": "Total shipping cost",
          "60a9oxfluds0": "Shipping cost paid by buyer",
          "60a9oxflufk0": "Shipping cost paid by seller",
          "60a9oxfluhc0": "Discount",
          "60a9oxfluiw0": "Fees and charges",
          "60a9oxfluko0": "Commission",
          "60a9oxflum40": "Service fee",
          "60a9oxflunw0": "Transaction fee",
          "60a9oxflupk0": "Actual amount paid by buyer",
          "60a9oxflur40": "Shipping fee",
          "60a9oxflut80": "Packaging material fee",
          "60a9oxfluus0": "Other costs",
          "60a9oxfluwg0": "Product cost",
          "60a9oxfluyc0": "Estimated profit",
          "60a9pmw41sg0": "Shopee shipping subsidy",
          "60a9qjl9dao0": "Seller Voucher",
          "60a9qtr2gzk0": "Shopee coins deduction"
        },
        "index": {
          "60a9ncf9km00": "Order details",
          "60a9ncf9kys0": "Order number:",
          "60a9ncf9l180": "Current currency unit:",
          "60a9ncf9l2w0": "Site:",
          "60a9ncf9l4o0": "Exchange rate:"
        }
      },
      "orderStatistics": {
        "constant": {
          "5zmqukt4k5w0": "By store",
          "5zmqukt4loo0": "Price range",
          "5zmqukt4lu40": "Date",
          "5zmqukt4ly80": "Number of customers (individuals)",
          "5zmqukt4m1s0": "Number of orders (transactions)",
          "5zmqukt4m600": "Total order amount",
          "5zmqukt4m980": "Total payment amount",
          "5zmqukt4mcw0": "Number of paid orders (transactions)",
          "5zmqukt4mfo0": "Number of paid customers",
          "5zmqukt4mi40": "Total refund amount",
          "5zmqukt4mlw0": "Number of refund orders (transactions)",
          "5zmqukt4mqs0": "Number of customers refunded",
          "5zmqukt4muc0": "Average order value",
          "5zmqukt4nj40": "Number of valid orders",
          "5zmqukt4o100": "Total valid sales",
          "5zmqukt4o134": "{0} SKU categories",
          "5zmqukt4o155": "{0} or more SKU categories",
          "5zmqukt4o177": "Number of SKU categories"
        },
        "hooks": {
          "5zmr6ze850o0": "Date filter"
        },
        "index": {
          "5zmr6pphrw00": "Order statistics",
          "5zmr6pphsek0": "Filter",
          "5zmr6pphsi40": "Data indicator trend chart"
        }
      },
      "overview": {
        "constant": {
          "5zmqj6xm7gs0": "Real-time data",
          "5zmqj6xm8840": "Hot-selling platform",
          "5zmqj6xm8bs0": "Hot-selling store",
          "5zmqj6xm8ec0": "Hot-selling products"
        },
        "index": {
          "5zmr4qhws3s0": "Data overview"
        }
      },
      "packagingMaterialsStatistics": {
        "components": {
          "60aa48gpg940": "Data statistics"
        },
        "constant": {
          "60aa3i4w8840": "Total packaging quantity",
          "60aa3i4w8mk0": "Order usage quantity",
          "60aa3i4w91s0": "Total amount",
          "60aa3i4w9940": "Code",
          "60aa3i4w9b00": "Name",
          "60aa3i4w9cs0": "Ranking",
          "60aa3i4w9e80": "Average unit price",
          "60aa3i4w9g00": "Subtotal"
        }
      },
      "productSales": {
        "components": {
          "60aa1gn2j3o0": "Specifications",
          "60aa1gn2jms0": "Product Sku",
          "60aa24ijr500": "Data statistics"
        },
        "constant": {
          "60aa0cmsgck0": "Number of product types",
          "60aa0cmsgwk0": "Product sales volume",
          "60aa0cmsh000": "Product cost",
          "60aa0cmsh280": "Quantity of returns",
          "60aa0cmsh4g0": "Refund amount",
          "60aa0cmsh780": "Sales amount",
          "60aa0cmsh9s0": "Refund cost",
          "60aa0cmshbw0": "Number of sales orders",
          "60aa0vmcdtw0": "Product Sku",
          "60aa0vmcec00": "Product name"
        }
      },
      "productStatistics": {
        "components": {
          "60a9yyz55n00": "Data indicator trend chart",
          "60a9yyz565o0": "Percentage (%)"
        },
        "constants": {
          "60a9xumyah40": "Date",
          "60a9xumyav40": "Total products",
          "60a9xumyay40": "Number of products for sale",
          "60a9xumyb000": "Proportion of products for sale",
          "60a9xumyb1s0": "Number of products with sales",
          "60a9xumyb5k0": "Turnover rate",
          "60a9xumyb740": "Number of new products for sale",
          "60a9xumyb8s0": "Proportion of new products for sale"
        }
      },
      "profitDetail": {
        "components": {
          "60a9u37djso0": "Order details",
          "60a9u37dkc40": "Shop details",
          "60a9vuvtmfs0": "Shop:",
          "60a9vuvtms80": "Site:",
          "60a9wn2oggk0": "Product ID",
          "60a9wn2ogv80": "Platform SKU",
          "60a9wn2ogz40": "Product Sku",
          "60a9wn2oh1s0": "Product cost",
          "60a9wn2oh4s0": "Platform refund amount:",
          "60a9wn2oh7g0": "Profit:",
          "60a9wn2oha00": "Sales profit margin:"
        },
        "constant": {
          "60a9mn7mnho0": "Based on order details",
          "60a9mn7mo0g0": "By store",
          "60a9mn7mo4c0": "Order number",
          "60a9mn7mo780": "Package number",
          "60a9mn7mo9g0": "Waybill number",
          "60a9mn7mod80": "Product title",
          "60a9mn7mofk0": "Product ID",
          "60a9mn7mohs0": "Platform SKU",
          "60a9mn7mok40": "Product Sku",
          "60a9mn7momg0": "Order time",
          "60a9mn7mook0": "Settlement time",
          "60a9mn7mor40": "Payment time",
          "60a9mn7mouc0": "Shipping time",
          "60a9mn7moww0": "Deadline for shipping",
          "60a9skxqv140": "Number of orders",
          "60a9skxqvhc0": "Sales amount",
          "60a9skxqvko0": "Actual payment by buyer",
          "60a9skxqvn80": "Estimated repayment amount",
          "60a9skxqvpo0": "Settled amount",
          "60a9skxqvs00": "Pending settlement amount",
          "60a9skxqvug0": "Cost",
          "60a9skxqvyo0": "Product cost",
          "60a9skxqw0o0": "Advertising cost",
          "60a9skxqw380": "Operating cost",
          "60a9skxqw5g0": "Packaging cost",
          "60a9skxqw7w0": "Other costs",
          "60a9skxqwbg0": "Profit",
          "60a9skxqwdw0": "Cost profit margin",
          "60a9skxqwgg0": "Sales profit margin",
          "60a9skxqwiw0": "Today's data",
          "60a9skxqwl40": "Yesterday's data",
          "60a9skxqwnw0": "Summary data",
          "60a9v7pp1n00": "Estimated Payment Amount",
          "60a9v7pp2qs0": "Original order amount",
          "60a9v7pp30g0": "Actual payment by buyer",
          "60a9v7pp33g0": "Packaging material fee",
          "60a9v7pp3680": "Other costs",
          "60a9v7pp38w0": "Profit",
          "60a9v7pp3bo0": "Cost profit margin",
          "60a9v7pp3fo0": "Sales profit margin",
          "60y9bytj9u80": "Third-party warehouse handling fee",
          "60y9bytjb480": "Third-party warehouse shipping fee"
        }
      },
      "profitsOverview": {
        "constant": {
          "5zmqie08y6k0": "Core indicators",
          "5zmqie08yp40": "Trend of core indicators",
          "5zmqie08ytg0": "Expense analysis",
          "5zmqie08ywk0": "Profit analysis",
          "5zmqie08yzo0": "Platform repayment amount",
          "5zmqie08z2o0": "Month-over-month",
          "5zmqie08z5c0": "Packaging material fee",
          "5zmqie08z7w0": "Actual payment by buyer",
          "5zmqie08zak0": "Profit",
          "5zmqie08zd40": "Product cost",
          "5zmqie08zfo0": "Cost profit margin",
          "5zmqie08zig0": "Other costs",
          "5zmqie08zks0": "Sales profit margin"
        },
        "index": {
          "5zmr3g7jskw0": "Profit overview"
        }
      },
      "saleDetail": {
        "index": {
          "5zmr52c50ng0": "Data overview",
          "5zmr52c517g0": "Product sales volume statistics details",
          "5zmr52c51bk0": "Data indicator trend chart",
          "5zmvji9oxrk0": "Product ID",
          "5zmvji9oz0w0": "Platform SKU",
          "5zmvji9oz700": "Platform",
          "5zmvji9ozao0": "Store",
          "5zmvjnye99g0": "Product dimension",
          "5zmvlw6cgzg0": "specification",
          "603i2ug6f3k0": "Start Time",
          "603i2ug6gbs0": "End Time",
          "603i2ug6gjc0": "You can only check the data of the past 6 months. Please select the time again"
        }
      },
      "salesStatistics": {
        "constant": {
          "5zmqf0amgts0": "By SPU (Product)",
          "5zmqf0ami0c0": "By SKU (Single item)",
          "5zmqf0ami6o0": "By store",
          "5zmqf0amib40": "Price range",
          "5zmqf0amifc0": "Sales range",
          "5zmqf0amilc0": "Total products",
          "5zmqf0amiqg0": "Number of paid orders",
          "5zmqf0amiuo0": "Sales volume",
          "5zmqf0amj000": "Total payment amount",
          "5zmqf0amj440": "Number of refund orders",
          "5zmqf0amj8c0": "Refund amount",
          "5zmqf0amjcw0": "Product title",
          "5zmqf0amjhc0": "Product ID",
          "5zmqf0amjkw0": "Platform SKU"
        },
        "hooks": {
          "5zmr1xvtlyw0": "Date filter"
        },
        "index": {
          "5zmr18mjbic0": "Sales statistics"
        }
      },
      "shopStatistics": {
        "components": {
          "60a9ixf7pqs0": "Shop details",
          "60a9ixf7q7w0": "Shop:",
          "60a9ixf7qcg0": "Number of customers:",
          "60a9ixf7qfc0": "Number of orders:",
          "60aacanra200": "Data statistics"
        },
        "constant": {
          "60a9gbqw2w80": "Number of customers",
          "60a9gbqw3os0": "Number of orders",
          "60a9gbqw3sk0": "Total order amount",
          "60a9gbqw3v80": "Total payment amount",
          "60a9gbqw3xg0": "Refund amount",
          "60a9gbqw3zw0": "Average order value",
          "60a9gbqw42c0": "Payment order quantity",
          "60a9gbqw4d40": "Number of paying customers",
          "60a9gbqw4i40": "Number of refund orders",
          "60a9gbqw4po0": "Number of refund customers"
        }
      }
    },
    "warehouse": {
      "baseIndex": {
        "5wfvehrixdc0": "the Package Has Been Put into Storage Today.",
        "5wfvehrixtk0": "Sku on Shelves Today",
        "5wfvehrj1ts0": "Select a Repository",
        "5wfvehrj1zs0": "Receipt",
        "5wfvehrj24w0": "Manual Storage",
        "5wfvehrj29k0": "Manual Outbound",
        "5wfvehrj2ig0": "Item Picking",
        "5wfvehrj2ng0": "Product Query",
        "5wfvehriixa0": "Warehouse",
        "5wfvehrj2na0": "Scan Delivery",
        "619ereolw8g0": "Return in"
      },
      "components": {
        "packageItem": {
          "5rnxjrj9pt00": "Please Enter/Scan the Logistics Order Number and Purchase Order Number.",
          "5rnxl2ugdn80": "Type to Be Stored",
          "5rnxl2uge480": "Number of Data to Be Stored",
          "5rnxl2ugey80": "Remarks"
        }
      },
      "goodsSearch": {
        "baseIndex": {
          "5weqyxxhmtg0": "Product Query",
          "5weqyxxhnfg0": "Scan Product Sku/Product Code",
          "5weqyxxhnjw0": "Please Scan the Product Information",
          "5weqyxxhnms0": "Enter Product Sku/Product Code",
          "5weqyxxhnpk0": "Loading",
          "5weqyxxhnsk0": "This Product Is Not Found."
        },
        "components": {
          "GoodsCard": {
            "GoodsInfo": {
              "5wer21zf7rk0": "Product Code",
              "5wer21zf8jg0": "Sales Volume in the past 7/30/60 Days"
            },
            "index": {
              "5wer2vtlaf40": "Sub-Product"
            },
            "StorageInfo": {
              "5wer3yv4lx40": "Position",
              "5wer3yv4mgo0": "Total Inventory",
              "5wer3yv4o8g0": "Available",
              "5wer3yv4ofw0": "Pre-Occupation"
            }
          }
        }
      },
      "processStorage": {
        "baseIndex": {
          "5rny2tblyc00": "Scan Products",
          "5rny2tblz7g0": "Enter/Scan Product Sku and Product Code",
          "5rny2tblzds0": "End This Warehousing",
          "5rny2tblzkw0": "Outbound",
          "5rny2tblzps0": "Warehousing",
          "5rny2tblztk0": "Item Not Found",
          "5rny2tblzx00": "the Combination Cannot Be Put into Storage.",
          "5rny2tbm00g0": "Loading",
          "5rny3yibbno0": "Success",
          "5rny51km7e40": "the Outbound Quantity Cannot Exceed",
          "5rolykv54d00": "Enter a Product Sku"
        },
        "components": {
          "GoodsPopup": {
            "5rnxodldrdc0": "Species",
            "5rnxodlds640": "Pieces",
            "5rnxodldsdc0": "Submit"
          },
          "SetQuantityDialog": {
            "5rnxpb2qwsg0": "Please Enter the Quantity",
            "5rnxu9u7r2c0": "This Time",
            "5ron09jvyh80": "Number",
            "5rnxpb2qxg00": "the Quantity Cannot Be Empty.",
            "5rnxu9u7q4w0": "the Number Cannot Exceed"
          }
        },
        "processGoodsWithStoragePage": {
          "baseIndex": {
            "5rnxzgpf7i00": "Commodity",
            "5rnxzgpf8co0": "Name:",
            "5rnxzgpf8io0": "Specifications:",
            "5rnxzgpf8mg0": "Number of Objects to Be Stored:",
            "5rnxzgpf8q40": "This Time",
            "5rnxzgpf8tk0": "No Position",
            "5rnxzgpf8ww0": "No Position Selected",
            "5rnxzgpf90g0": "Delete",
            "5rnxzgpf93s0": "Change Position",
            "5rnxzgpf97c0": "Outbound",
            "5rnxzgpf9ao0": "Warehousing",
            "5rnxzgpf9e80": "the Position Cannot Be Empty. Please Check the Position.",
            "5rnxzgpf9hs0": "Operating",
            "5rnxzgpf9l80": "Data Exception, Please Contact Customer Service",
            "5rny0rcmwbk0": "in Progress",
            "5werec5q0lc0": "OK"
          }
        },
        "scanStorageCellPage": {
          "baseIndex": {
            "5rny1og9cek0": "Scan Positions",
            "5rny1og9d740": "Please Scan/Enter the Position Number",
            "5rny1og9ddc0": "Please Scan the Position",
            "5rny1og9dgw0": "Querying",
            "5roninktevk0": "This Product Is Not in Stock at This Position."
          }
        }
      },
      "putaway": {
        "baseIndex": {
          "5rnyj390bf00": "Scan Packages",
          "5rnyj390c1o0": "Please Enter/Scan the Logistics Order Number and Purchase Order Number.",
          "5rnxm6vsad80": "Please Scan the Package",
          "5rnyj390c7g0": "Purchase Order to Be Signed",
          "5rnyj390cb40": "Loading",
          "5rnyj390ce80": "No Data Available",
          "5rnyj390cho0": "Purchase Order Signed Successfully"
        },
        "components": {
          "WaitReceivePurchaseOrderList": {
            "5rnyh09ydbg0": "No Data Available"
          }
        },
        "purchaseOrderInfoPage": {
          "baseIndex": {
            "5rnyibnc0h40": "Scan Packages",
            "5rnyibnc14g0": "Name:",
            "5rnyibnc1a00": "Specifications:",
            "5rnyibnc1dw0": "Code:",
            "5rnyibnc1hc0": "It's Already the End",
            "5rnyibnc1l00": "Direct Storage",
            "5rnyibnc1ow0": "Scan Goods for Storage",
            "5rnyibnc1sg0": "after the Inventory Is Directly Put into Storage, the Inventory Will Increase. Are You Sure to Put It into Storage?",
            "5rnyibnc1w80": "Warehousing..."
          }
        }
      },
      "scanReturn": {
        "components": {
          "WaitReturnGoodsList": {
            "619evhn1suw0": "Type to Be Stored",
            "619evhn1txg0": "Number of Data to Be Stored",
            "619evhn1u580": "No Data Available"
          }
        },
        "hooks": {
          "useReturnGoods": {
            "619eub7jj1s0": "Warehousing...",
            "61wzjfwnawk0": "The quantity of incoming goods cannot exceed {0}"
          },
          "useScan": {
            "619euwhlq5o0": "Loading",
            "619euwhlqyg0": "No Data Available"
          }
        },
        "returnOrderInfoPage": {
          "index": {
            "619etj4urs80": "Return in",
            "619etj4usrk0": "Name:",
            "619etj4usxs0": "Specifications:",
            "619etj4ut1s0": "It's Already the End",
            "619etj4ut540": "Direct Storage",
            "619etj4ut980": "Scan Goods for Storage",
            "619etj4uteg0": "after the Inventory Is Directly Put into Storage, the Inventory Will Increase. Are You Sure to Put It into Storage?"
          }
        },
        "constant": {
          "619espdpd100": "Scan Packages",
          "619espdpe9k0": "Return order pending for warehouse entry"
        },
        "index": {
          "619es2vhols0": "Please Scan the Package",
          "619es2vho3o0": "Return in",
          "619es2vhoi80": "Please enter/scan the waybill number, order number, or package number",
          "619es2vhooo0": "Return order successfully entered into warehouse"
        }
      },
      "scanShipment": {
        "components": {
          "List": {
            "5wsld4f61gw0": "No Data Available"
          },
          "OrderPackageItemMedia": {
            "5wsld4f61gw0": "1. the Uploaded Images/Videos Can Be Viewed in the Image Space.",
            "5wsld4f61gw1": "2. the Video Cannot Exceed 30M and the Duration Cannot Exceed 1 Minute.",
            "5wsld4f61gw2": "Upload Images",
            "5wsld4f61gw3": "Upload Video",
            "5wsld4f61gw4": "Select Video",
            "5wsld4f61gw5": "Picture",
            "5wsld4f61gw6": "Video",
            "5wsld4f61gw7": "The Duration Cannot Exceed 1 Minute."
          },
          "PackageItemGoods": {
            "5wsldq8io7w0": "Specifications"
          },
          "ShipmentButton": {
            "5wsle2o8bvc0": "Delivery"
          }
        },
        "hooks": {
          "useList": {
            "5wslbuh0fts0": "the Package Has Been Scanned This Time.",
            "5wslbuh0j1c0": "Loading",
            "5wslbuh0j8g0": "No Data Available"
          },
          "useShipmentPackage": {
            "5wslcgxgpio0": "in Operation",
            "5wslcgxgu5g0": "Shipment Failed",
            "5wslcgxgug00": "Shipped Successfully"
          }
        },
        "index": {
          "5wsl89cm3gw0": "Scan Delivery",
          "5wsl89cm7z80": "Scan Or Enter the Package Number/Waybill Number",
          "5wsl89cm8980": "Scanned",
          "5wsl89cm8ds0": "Please Scan the Package Information",
          "5wsl89cm8hc0": "Loading",
          "5wsl89cm8hc1": "Automatic Delivery after Scanning"
        },
        "scannedListPage": {
          "index": {
            "5wslasiw3rc0": "Scanned",
            "5wslasiw8r40": "Are You Sure You Want to Remove the Package?",
            "5wslasiw9200": "After Returning, the Scanned Package List Will Be Cleared. Are You Sure You Want to Continue?",
            "5wslasiw9940": "All",
            "5wslasiw9f40": "No Delivery Package Available"
          }
        }
      },
      "singlePick": {
        "baseIndex": {
          "5wesij4wgig0": "Item Picking",
          "5wesij4whps0": "Scan/Incoming Wave Times",
          "5wesij4whv40": "Are You Sure You Want to Mark It as Complete?",
          "5wesij4whyk0": "to Avoid Repeated Picking, We Recommend That You Confirm with the User to Whom Your Account Belongs Or Check the Operation Log before Picking.",
          "5wesij4wi1s0": "after Marking, the Wave {0} Cannot Be Picked.",
          "5wesij4wi5s0": "Account:{0} Operate Picking at {1}",
          "5wesij4wi0s0": "and the Wave Will Enter the State to Be Packaged."
        },
        "components": {
          "WaveList": {
            "WaveItem": {
              "5wesqu5fcgs0": "Picked/to Be Picked",
              "5wesqu5fi8s0": "Product Information",
              "5wesqu5fihk0": "Creation Time",
              "5wesqu5fimg0": "Founder",
              "5wesqu5fiv00": "More",
              "5wesqu5fizs0": "Start Picking",
              "5wesqu5fj3w0": "Mark Complete",
              "5wesqu5fj800": "Picking Log",
              "5wesqu5fjck0": "{0} Skus, Total {1}"
            }
          }
        },
        "hooks": {
          "useOperate": {
            "5wespf869ys0": "Loading",
            "5wespf86efs0": "Continue Picking",
            "5wespf86et80": "Picking Log",
            "5wespf86eys0": "the Status of the Wave Has Been Updated. Please Drop down to Refresh the Page to View It."
          }
        },
        "subPages": {
          "pickLog": {
            "baseIndex": {
              "5weyeh0dlbs0": "Picking Log"
            },
            "components": {
              "LogItem": {
                "5weygrs8su40": "Product Sku",
                "5weygrs8xvc0": "Picking Quantity"
              }
            }
          },
          "picking": {
            "baseIndex": {
              "5wey0wt55a40": "Scan/Enter Sku and Product Code",
              "5wey0wt64ns0": "Sku,",
              "5wey0wt650g0": "Total",
              "5wey0wt65b00": "Num",
              "5wey1e5fupo0": "Remaining Picking:"
            },
            "components": {
              "SetPickQuantityDialog": {
                "5wey82q92us0": "Fill in the Picked Quantity",
                "5wey82q99rg0": "Please Enter",
                "5wey82q9a100": "Please Enter the Picked Quantity",
                "5wey82q9a1g0": "the Quantity Picked Cannot Be Greater than the Quantity to Be Picked:{0}"
              },
              "SkuItem": {
                "5wey9z9uhxs0": "Product Code",
                "5wey9z9uoe00": "Position",
                "5wey9z9uook0": "Picked",
                "5wey9z9uoyw0": "to Be Picked"
              },
              "WavePickFinishDialog": {
                "5weycylwol80": "Prompt",
                "5weycylwsss0": "I Already Know",
                "5weycylwt1c0": "the Current Wave Picking Has Been Completed!"
              }
            },
            "hooks": {
              "usePicking": {
                "5wey5ujb4xs0": "the Current Wave Does Not Exist.",
                "5wey5ujbaeo0": "Item Sku:{0} Finished Picking"
              }
            }
          }
        }
      }
    }
  },
  "system": {
    "confirm": "OK",
    "cancel": "Cancel",
    "pleaseSelect": "Please Select",
    "5rnxe856we80": "Prompt",
    "5rnxe856wl00": "Confirm",
    "loading": "Loading",
    "copySuccess": "Copied Successfully",
    "emptyShelfCell": "No Position",
    "versionInfo": "Version Information",
    "language": {
      "toggle": "Switch Language",
      "selectEmpty": "Please Select a Language"
    },
    "tabBar": {
      "stat": "Data",
      "home": "Warehouse",
      "my": "Mine"
    },
    "update": {
      "title": "Update Prompt",
      "forceUpdate": "Find the New Version and Restart It Immediately.",
      "fail": "Update Failed",
      "newVersion": "New Version Found",
      "updateNow": "Update Now",
      "updateLater": "next Update"
    }
  }
}