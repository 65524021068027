export default {
  "components": {
    "Account": {
      "UnReceivedCodeLink": {
        "5xz3hmxq1380": "未收驗證碼?",
        "5xz3hmxq4ws0": "關閉",
        "5xz3hmxq5800": "可能被識別為垃圾郵件,請至垃圾郵件中查看",
        "5xz3hmxq5fc0": "請檢查您輸入的郵箱是否正確,或者嘗試使用其他郵箱註冊",
        "5xz3hmxq5lo0": "請檢查當前網絡問題是否正常",
        "5xz3hmxq5s40": "未收到驗證碼?"
      }
    },
    "Empty": {
      "5weyj6xvl9o0": "暫無數據"
    },
    "ProComponent": {
      "ProConfirmPopup": {
        "5weyne0t7t40": "提示",
        "5weyne0tctw0": "確定",
        "5weyne0td2o0": "取消"
      }
    },
    "ScanCodeView": {
      "ScanCodeView": {
        "5weysudo0zk0": "此快遞單號存在非法字符,請重新掃描"
      },
      "ScanCameraApp": {
        "5weas1do0zk0": "正在獲取相機信息"
      }
    },
    "SearchBackBar": {
      "5weyueldnb00": "篩選"
    },
    "SelectView": {
      "5weyy43t5v00": "請輸入搜索關鍵詞",
      "5weyy43tasw0": "暫無數據"
    },
    "ShopTag": {
      "60aatigyeig0": "跨境"
    },
    "Warehouse": {
      "ScanModeSelect": {
        "5wez10tdkk80": "請選擇掃描方式",
        "5wez10tdpow0": "激光掃描",
        "5wez10tdpxg0": "相機掃描"
      },
      "StockModeSelect": {
        "5wez1zd63kw0": "請選擇入庫方式",
        "5wez1zd68ag0": "請選擇出庫方式"
      },
      "WarehouseSelect": {
        "5wez1mdf9v80": "請選擇倉庫",
        "5wez1mdfep80": "沒有倉庫權限,請聯繫主賬號添加"
      }
    }
  },
  "constants": {
    "package": {
      "5wskfpirawg0": "待提交",
      "5wskfpirer00": "失敗",
      "5wskfpirey40": "成功"
    },
    "platform": {
      "5zwog8ot6ow0": "手工订单",
      "5zwog8ot8zs0": "零售订单",
      "602uc1886b40": "Lazada全託管"
    },
    "shipment": {
      "5wsjxwq908g0": "掃描1次",
      "5wsjxwq96nc0": "掃描搜尋包裹並提交發貨",
      "5wsjxwq970k0": "掃描2次",
      "5wsjxwq97880": "掃描後,先搜尋包裹,再次掃描才自动发货",
      "5wsjxwq97ek0": "手動",
      "5wsjxwq97lo0": "自動",
      "5wsjxwq97s80": "忽略",
      "5wsjxwq97yo0": "強制",
      "5yjh2ncp5go0": "開啟",
      "5yjh2ncp6p80": "關閉"
    }
  },
  "pages": {
    "account": {
      "components": {
        "TwoFactorAuthenticationPopup": {
          "60ae807iv8k0": "異地登錄安全驗證",
          "60ae807ivnc0": "您正在",
          "60ae807ivq00": "異地登錄",
          "60ae807ivq01": "，",
          "60ae807ivrw0": "為保障賬號安全，登錄前請完成驗證。",
          "60ae807ivtg0": "獲取驗證碼",
          "60ae807ivvs0": "立即驗證",
          "60ae807ivys0": "請輸入郵箱驗證碼",
          "60ae807iw0s0": "請輸入簡訊驗證碼",
          "60ae807iw3o0": "請輸入驗證碼!",
          "60ae807iw5w0": "正在獲取驗證碼",
          "60ae807iw7s0": "已成功发送验证码",
          "60ae8fkmn680": "重新發送{0}",
          "60gic918gtg0": "验证成功",
          "60gicqrsmqk0": "登錄地"
        }
      },
      "currencyAndTimeZone": {
        "currencyAndTimeZone": {
          "index": {
            "60wmecfnlrg0": "幣種",
            "60wmecfnmfo0": "請選擇幣種",
            "60wmecfnmfo2": "系統內商品成本、利潤等金額,將用{0}展示;選擇後,不支持修改",
            "60wmecfnmjs0": "時區",
            "60wmecfnmms0": "請選擇時區",
            "60wmecfnmpo0": "保存",
            "60wmecfnms40": "提示",
            "60wmecfnmuo0": "選擇了幣種後,不支持再修改,確定選擇當前幣種?",
            "60wmecfnmws0": "保存成功",
            "60wmecfnlrg4": "選擇幣種與時區"
          }
        }
      },
      "forget-password": {
        "index": {
          "5weq2lrg6780": "手機號碼",
          "5weq2lrg7a40": "請輸入手機號",
          "5weq2lrg7fg0": "新密碼",
          "5weq2lrg7io0": "請輸入密碼",
          "5weq2lrg7lk0": "密碼至少8個字符,包含字符、數字、特殊符號",
          "5weq2lrg7os0": "確認密碼",
          "5weq2lrg7s00": "驗證碼",
          "5weq2lrg7uw0": "請輸入驗證碼",
          "5weq2lrg8580": "重置密碼",
          "5weq2lrg88k0": "手機號格式不正確",
          "5weq2lrg8bg0": "兩次輸入的密碼不一致",
          "5weq2lrg8eg0": "正在獲取驗證碼",
          "5weq2lrg8h40": "驗證碼已發送",
          "5weq2lrg8jw0": "修改成功"
        },
        "page": {
          "forgetPassword": "忘記密碼"
        }
      },
      "login": {
        "5rnx1lpiie80": "賬號",
        "5rnx1lpiwp40": "密碼",
        "5rnx1lpiwz40": "登錄",
        "5rnx1lpix540": "忘記密碼",
        "5rnx1lpix9s0": "註冊賬號",
        "5rnx1lpixes0": "請輸入郵箱/手機號碼/子帳號",
        "5rnx1lpixko0": "郵箱/手機號碼/子帳號必填",
        "5rnx1lpixps0": "賬號不存在,請重新輸入",
        "5rnx1lpixus0": "密碼必填",
        "5rnx1lpiy5g0": "密碼錯誤,請重新輸入",
        "5rnx1lpiyk00": "賬號不存在",
        "5rnx1lpiyqc0": "密碼不正確",
        "5rnx1lpiyv00": "獲取驗證碼",
        "5rnx1lpiyyc0": "秒重新獲取",
        "5rnx1lpiz2g0": "重新獲取"
      },
      "register": {
        "register": {
          "placeholder": {
            "email": "請輸入電子郵箱",
            "password": "請輸入密碼",
            "password2": "再次確認密碼",
            "mobile": "請輸入手機號",
            "emailValidateCode": "請輸入郵箱驗證碼",
            "mobileValidateCode": "請輸入手機驗證碼"
          },
          "getValidateCodeBtn": "獲取驗證碼",
          "agreeCheckTipsBefore": "我已閱讀並同意",
          "agreeCheckTipsAfter": {
            "userAgreement": "用戶服務協議、",
            "privacyAgreement": "隱私協議"
          },
          "confirm": "免費註冊",
          "success": {
            "message": "註冊成功",
            "tips": "請按以下步驟登錄並使用EasyBoss",
            "copyLink": "複製鏈接",
            "jumpToPc": "進入電腦端軟件界面"
          },
          "validatorError": {
            "password": "密碼至少8個字符,包含字符、數字、特殊符號",
            "email": "郵箱格式不正確",
            "mobile": "手機號格式不正確",
            "checkedAgree": "請選擇協議",
            "passwordNotSame": "兩次輸入的密碼不一致"
          },
          "gettingValidateCode": "正在獲取驗證碼",
          "validateCodeSendSuccess": "驗證碼已發送",
          "mobileCountryCode": "請選擇區號",
          "5weqrdhch7o0": "註冊",
          "5weqrdhci4w0": "密碼至少8個字符,包含字符、數字、特殊符號",
          "5weqrdhci9k0": "確認密碼",
          "5weqrdhcicw0": "福州劍奇雲網絡科技有限公司",
          "5weqrdhcijs0": "版權所有",
          "5weqrdhcimw0": "閩ICP備2020020145號-5",
          "index": {
            "61f8z24qhjg0": "圖形驗證碼不正確"
          }
        },
        "copy": {
          "success": "複製成功"
        },
        "page": {
          "register": "註冊"
        },
        "greatboss": {
          "register": {
            "success": {
              "tips": "請按以下步驟登錄並使用GreatBoss"
            }
          }
        }
      }
    },
    "distribution": {
      "bind": {
        "protocol": {
          "index": {
            "60j4x24i71g0": "《EasyBoss·分銷合作須知》",
            "60j4x24i71g2": "尊敬的用戶,歡迎您使用EasyBoss分銷合作功能! 在使用EasyBoss分銷合作功能之前,您應當認真閱讀並遵守《EasyBoss-分銷合作須知》。",
            "60j4x24i71g3": "1.供應商、分銷商可通過EasyBoss分銷工具開展分銷合作業務,雙方均在自願、自主基礎上展開合作,對於合作中產生的鋪貨、訂單、售後、資金等相關問題,請供分銷雙方友好解決,easyBoss不提供任何擔保且對雙方的合作不負有任何義務。",
            "60j4x24i71g4": "2.在EasyBoss上傳、編輯的商品信息(包括但不限於供貨價、產品的描述和說明、相關圖片、視頻等)由供應商自行提供,供應商應保證商品信息及自身行為合法性且不侵犯任何第三方權益,如因供應商進行上述行為所產生的一切後果,由供應商自行承擔,同時EasyBoss保留相關追訴權利。",
            "60j4x24i71g5": "3.若您使用「分銷資金」功能,雙方應線下支付錢款;EasyBoss僅提供記賬功能,不對雙方資金往來提供清算、分賬及其他資金相關服務,亦不對因此產生的糾紛負責。",
            "60j4x24i71g6": "4.分銷商將訂單推送給供應商處理,供應商由於任何原因,導致訂單延遲發貨、取消,由此產生的影響,請供分銷雙方友好解決,easyBoss不提供任何擔保且對雙方的合作不負有任何義務。",
            "60j4x24i71g8": "分銷邀請"
          }
        },
        "bind": {
          "index": {
            "60j51capb2s0": "供應商{0}邀請您與他建立合作,請選擇是否接受合作邀請",
            "60j51capb6s0": "接受邀請後,請耐心等待供應商審核,審核通過後可展示合作",
            "60j51capb9g0": "可填寫申請信息,以便提供快速通過您的申請",
            "60j51capbcg0": "我已閱讀並同意",
            "60j51capbfk0": "暫不接受",
            "60j51capbi40": "接受",
            "60j51capbks0": "點此登錄",
            "60j51capbps0": "點擊",
            "5yg2sdxxi8t0": "供應商{0}邀請您與他建立合作,請先登錄EasyBoss ERP",
            "60j51capbps1": "加載中..."
          }
        },
        "hooks": {
          "useDistributionBind": {
            "5yg2wlqzuo00": "操作成功",
            "5yg2wlqzus40": "提示",
            "5yg2wlqzuus0": "我已知曉",
            "5yg2wlqzuxc0": "合作邀請鏈接已過期,請聯繫供應商索要新的邀請合作鏈接",
            "5yg2wlqzuxa0": "您已和供應商{0}建立合作!",
            "5yg2wlqzuxt0": "您已向供應商{0}發起合作申請! 請等待供應商審核",
            "5yg2wlqzuxi0": "供應商{0}已與您終止合作,請您聯繫供應商",
            "5yg2wlqzuxp0": "供應商{0}狀態異常,暫時無法合作",
            "5yg2wlqzuxh0": "供應商已關閉分銷流程,無法合作",
            "5yg2wlqzuyr0": "請使用主賬號登錄建立合作關係",
            "5yg2wlqzuo01": "請閱讀並同意分銷合作須知"
          }
        },
        "DistributionCoopConfirmDialog": {
          "index": {
            "5zqoed6x0k40": "提示",
            "5zqoed6x2500": "我已閱讀並同意{0}",
            "5zqoed6x2ds0": "請閱讀並同意分銷合作須知"
          }
        },
        "default": {
          "index": {
            "60j4x24i71g6-1": "操作成功,請前往PC端登錄{0}查看",
            "60j4x24i71g6-2": "複製鏈接",
            "60j4x24i71g6-3": "複製成功"
          }
        }
      },
      "constant": {
        "constant": {
          "distribution": {
            "5yg7j4qkamo0": "合作中",
            "5yg7j4qkb0o0": "供應商已終止",
            "5yg7j4qkb3g0": "分銷商已終止",
            "5yg7j4qkb6c0": "供應商已拒絕",
            "5yg7j4qkb800": "分銷商已拒絕",
            "5yg7j4qkb9s0": "待供應商授權",
            "5yg7j4qkbco0": "待分銷商接受",
            "5yg7j4qkbe80": "待授權",
            "5yg7j4qkbfs0": "已拒絕",
            "5yg7j4qkbhg0": "已終止",
            "5yg7j4qkbht0": "狀態異常"
          }
        }
      }
    },
    "my": {
      "my": {
        "components": {
          "InputAutoFocusSettingSelect": {
            "5yjh4axepj00": "輸入框自動聚焦"
          },
          "ScanShipmentSettingSelect": {
            "5wsklmc59bk0": "掃描發貨"
          }
        }
      },
      "myIndex": {
        "5rnxe856up00": "個人中心",
        "5rnxe856vpg0": "貨代版",
        "5rnxe856vw00": "免費版",
        "5rnxe856vzo0": "付費版",
        "5rnxe856w380": "倉庫",
        "5rnxe856w780": "掃描方式",
        "5rnxe856wao0": "退出登錄",
        "5rnxe856whs0": "確認要退出登錄嗎?",
        "5rnxe856wq40": "語種",
        "5rnxe856wv40": "切換語言需要重啟應用",
        "5rnxe856wha0": "掃描發貨設置",
        "5rnxe856whb0": "加載中",
        "5rnxe856whb1": "使用相機掃描時,請不要再按壓激光掃描按鈕,設備可能會卡住",
        "5rnxe856whb2": "輸入框自動聚焦"
      }
    },
    "permission": {
      "404": {
        "60cyyf5ngl80": "暫無權限",
        "60cyyf5nhjo0": "暫無權限，請聯繫主帳號添加"
      }
    },
    "stat": {
      "HotGoodsSection": {
        "constant": {
          "5zmqkoscpn00": "按SKU(單品)",
          "5zmqkoscqlo0": "按SPU(產品)"
        },
        "index": {
          "5zmr5k20gnk0": "熱銷產品TOP10",
          "5zmr5k20i840": "規格",
          "5zmr5k20ifs0": "平台SKU",
          "5zmr5k20ikg0": "產品ID",
          "5zmr5k20iok0": "主貨號",
          "5zmr5k20it00": "店鋪",
          "5zmr5k20ixc0": "銷售額",
          "5zmr5k20j1g0": "銷量"
        }
      },
      "HotPlatformSection": {
        "index": {
          "5zmr5t9re380": "熱銷平台TOP5",
          "5zmr5t9rf3o0": "排名",
          "5zmr5t9rf7k0": "平台",
          "5zmr5t9rf9s0": "銷售額"
        }
      },
      "HotShopSection": {
        "index": {
          "5zmr60rhpes0": "熱銷店鋪TOP5",
          "5zmr60rhpyk0": "排名",
          "5zmr60rhq100": "店鋪",
          "5zmr60rhq300": "平台",
          "5zmr60rhq4k0": "銷售額"
        }
      },
      "StatShopSelect": {
        "index": {
          "5zmr8nz3oa40": "選擇平台",
          "5zmr8nz3pac0": "選擇站點",
          "5zmr8nz3pio0": "選擇店鋪"
        }
      },
      "TotalViewSection": {
        "constant": {
          "5zmqmu4tw1g0": "付款總額",
          "5zmqmu4twh40": "預計實收",
          "5zmqmu4twks0": "付款客戶數",
          "5zmqmu4twns0": "較昨日",
          "5zmqmu4twq80": "付款訂單量",
          "5zmqmu4twuk0": "退款客戶數",
          "5zmqmu4twx00": "退款訂單量",
          "5zmqmu4twzg0": "退款總額",
          "5zmqmu4tx3o0": "預估利潤",
          "5zmqmu4tx6g0": "利潤率"
        },
        "index": {
          "5zmr673frbk0": "當日數據，2小時更新一次"
        }
      },
      "common": {
        "60a99nlkohc0": "利潤明細",
        "60a9dq4u54g0": "商品銷售",
        "60a9d45degg0": "產品統計",
        "60a9eurfpww0": "包材統計",
        "60a9feawjog0": "店鋪統計",
        "60a9ht0fpgc0": "日期篩選",
        "60a9lb8cd0w0": "貨幣單位",
        "60a9lb8cdko0": "按訂單狀態",
        "60a9rrnp5rs0": "數據統計",
        "5saylxfm3ps0": "最大區間為3個月,請重新選擇查詢時間"
      },
      "components": {
        "CoreIndicator": {
          "5zmr3p5s6280": "核心指標",
          "5zmr3p5s6s40": "銷售額"
        },
        "CoreIndicatorChart": {
          "5zmr3x26fdo0": "核心指標趨勢",
          "5zmr3x26g500": "產品統計",
          "5zmr3x26g8g0": "百分比(%)"
        },
        "DataStatisticsSection": {
          "5zmr2qhmwkw0": "數據統計"
        },
        "DisbursementChartCard": {
          "5zmr43sgo9o0": "支出分析",
          "5zmr43sgomg0": "{0}佔比: {1}%",
          "5zmr43sgopc0": "總支出"
        },
        "GoodsItemSection": {
          "5zmr895tvq80": "商品SKU",
          "60kms1u2s700": "規格"
        },
        "GoodsSection": {
          "5zmr2yq2c0k0": "產品ID",
          "5zmr2yq2cek0": "主貨號",
          "5zmr2yq2ch80": "平台",
          "5zmr2yq2cj00": "店鋪",
          "5zmr2yq2ckk0": "付款訂單量",
          "5zmr2yq2cm80": "銷量",
          "5zmr2yq2cns0": "總付款金額",
          "5zmr2yq2cpc0": "總退款金額",
          "5zmr2yq2cr00": "規格",
          "5zmr2yq2css0": "平台SKU"
        },
        "OrderDistributionSection": {
          "5zmr7cert4c0": "付款訂單分佈"
        },
        "ProductDistributionSection": {
          "5zmr36t00b80": "有銷量的產品分佈"
        },
        "RankingTenList": {
          "5zmr4euc2940": "利潤分析TOP10",
          "5zmr4euc2z40": "排名",
          "5zmr4euc3300": "利潤",
          "5zmr4euc35s0": "銷售額",
          "5zmr4euc3880": "銷售利潤率",
          "5zmr4euc3bs0": "總利潤"
        },
        "StatDropdown": {
          "5zmr95mf3q00": "已選擇",
          "5zwtjniwy940": "個"
        },
        "StatSearchBar": {
          "5zmr9lrwj5g0": "請輸入",
          "5zmr9lrwknc0": "篩選"
        },
        "TableSection": {
          "5zmr7j0011s0": "日期",
          "5zmr7j001yw0": "客戶數",
          "5zmr7j002340": "訂單量",
          "5zmr7j002680": "訂單總額",
          "5zmr7j0028s0": "退款金額"
        },
        "StatDetailPopup": {
          "5zmw7ihk8580": "重置",
          "5zmw7ihk9n80": "確定"
        },
        "QueryBar": {
          "5saylxfm3p80": "最大區間為3個月,請重新選擇查詢時間",
          "5saylxfm4400": "僅支持查詢近3個月的數據,請重新選擇查詢時間"
        },
        "StatRadio": {
          "60aa4y1tqd40": "已選擇",
          "60aa4y1tqq80": "個",
          "60aa4y1tqss0": "全部"
        },
        "StatEmpty": {
          "60krhr1rgwk0": "暫無數據"
        }
      },
      "constant": {
        "5zmp81p6xo00": "付款訂單量",
        "5zmp81p6yuk0": "總付款金額",
        "5zmp81p6yzs0": "退款訂單量",
        "5zmp81p6z2w0": "總退款金額",
        "5zmp81p6z5s0": "數據總覽",
        "5zmp81p6z9k0": "利潤概覽",
        "5zmp81p6zcg0": "利潤明細",
        "5zmp81p6zlk0": "產品統計",
        "5zmp81p6zug0": "銷量統計",
        "5zmp81p70840": "訂單統計",
        "5zmp81p70fk0": "店鋪統計",
        "5zmp81p70j80": "商品銷售",
        "5zmp81p70lg0": "進出統計",
        "5zmp81p70o00": "包材統計",
        "5zmp81p70q40": "按天",
        "5zmp81p70sc0": "按周",
        "5zmp81p70uk0": "按月",
        "5zmp81p70wg0": "昨天",
        "5zmp81p70y80": "近7天",
        "5zmp81p710g0": "近30天",
        "5zmp81p713o0": "自訂日期",
        "5zmp81p715c0": "退款金額",
        "5zmp81p719c0": "下單時間",
        "5zmp81p71b00": "結算時間",
        "5zmp81p71dk0": "付款時間",
        "5zmp81p71f80": "發貨時間",
        "5zmp81p71jg0": "截至發貨",
        "5zmp81p71ok0": "銷售額",
        "5zmp81p71rg0": "預估回款金額",
        "5zmp81p71t80": "買家實付",
        "5zmp81p71v40": "商品成本",
        "5zmp81p71ws0": "其他成本",
        "5zmp81p71yg0": "包材費",
        "5zmp81p71zs0": "利潤",
        "5zmp81p72180": "成本利潤率",
        "5zmp81p72340": "銷售利潤率",
        "5zmp81p724w0": "按平台",
        "5zmp81p726c0": "按站點",
        "5zmp81p72800": "按店鋪",
        "5zmp81p729c0": "店鋪",
        "5zmp81p72aw0": "平台",
        "5zmp81p72f00": "站點",
        "6021uie62280": "銷量"
      },
      "hooks": {
        "5zmp74as2c00": "加載中"
      },
      "incomeAndExpenditureStatistics": {
        "constant": {
          "5zmqp7c2t700": "期末庫存",
          "5zmqp7c2u6c0": "期初庫存",
          "5zmqp7c2udo0": "採購入庫",
          "5zmqp7c2uhw0": "庫存調整入庫",
          "5zmqp7c2ulk0": "盤盈入庫",
          "5zmqp7c2uq80": "調撥入庫",
          "5zmqp7c2uu80": "退貨入庫",
          "5zmqp7c2uyg0": "手工退貨入庫",
          "5zmqp7c2v3o0": "其他入庫",
          "5zmqp7c2v800": "銷售出庫",
          "5zmqp7c2vc40": "庫存調整出庫",
          "5zmqp7c2vgg0": "盤虧出庫",
          "5zmqp7c2vkw0": "調撥出庫",
          "5zmqp7c2vp40": "手工銷售出庫",
          "5zmqp7c2vtk0": "其他出庫",
          "5zmqp7c2vzw0": "商品SKU",
          "5zmqp7c2w480": "商品名稱"
        },
        "hooks": {
          "5zmr7yzqthc0": "日期篩選",
          "5zmr7yzqtsw0": "選擇倉庫"
        },
        "index": {
          "5zmr7qd04is0": "出入統計"
        }
      },
      "index": {
        "5zmp4zbj9s00": "實時數據",
        "5zmp4zbjazc0": "當日數據，每2小時更新一次",
        "5zmp4zbjb3k0": "常用功能",
        "5zmvhhwoxlw0": "貨幣單位"
      },
      "orderDetail": {
        "constant": {
          "60a9oxflttc0": "訂單收入",
          "60a9oxflu740": "訂單原始金額",
          "60a9oxflu9o0": "產品價格",
          "60a9oxfluc40": "總運費",
          "60a9oxfluds0": "買家支付的運費",
          "60a9oxflufk0": "賣家支付的運費",
          "60a9oxfluhc0": "折扣",
          "60a9oxfluiw0": "費用與收費",
          "60a9oxfluko0": "佣金",
          "60a9oxflum40": "服務費",
          "60a9oxflunw0": "交易手續費",
          "60a9oxflupk0": "買家實付金額",
          "60a9oxflur40": "運費",
          "60a9oxflut80": "包材費",
          "60a9oxfluus0": "其他成本",
          "60a9oxfluwg0": "商品成本",
          "60a9oxfluyc0": "預估利潤",
          "60a9pmw41sg0": "Shopee運費補貼",
          "60a9qjl9dao0": "賣家優惠券",
          "60a9qtr2gzk0": "Shopee幣抵扣"
        },
        "index": {
          "60a9ncf9km00": "訂單明細",
          "60a9ncf9kys0": "訂單號碼：",
          "60a9ncf9l180": "當前金額單位：",
          "60a9ncf9l2w0": "站點：",
          "60a9ncf9l4o0": "匯率："
        }
      },
      "orderStatistics": {
        "constant": {
          "5zmqukt4k5w0": "按店鋪",
          "5zmqukt4loo0": "價格區間",
          "5zmqukt4lu40": "日期",
          "5zmqukt4ly80": "客戶數（個）",
          "5zmqukt4m1s0": "訂單量（筆）",
          "5zmqukt4m600": "訂單總額",
          "5zmqukt4m980": "付款總額",
          "5zmqukt4mcw0": "付款訂單量（筆）",
          "5zmqukt4mfo0": "付款客戶數（個）",
          "5zmqukt4mi40": "退款總額",
          "5zmqukt4mlw0": "退款訂單量（筆）",
          "5zmqukt4mqs0": "退款客戶數（個）",
          "5zmqukt4muc0": "客单價",
          "5zmqukt4nj40": "有效訂單量",
          "5zmqukt4o100": "有效銷售額",
          "5zmqukt4o134": "{0}個SKU品類",
          "5zmqukt4o155": "{0}個SKU品類及以上",
          "5zmqukt4o177": "SKU品類數"
        },
        "hooks": {
          "5zmr6ze850o0": "日期篩選"
        },
        "index": {
          "5zmr6pphrw00": "訂單統計",
          "5zmr6pphsek0": "篩選",
          "5zmr6pphsi40": "數據指標趨勢圖"
        }
      },
      "overview": {
        "constant": {
          "5zmqj6xm7gs0": "實時數據",
          "5zmqj6xm8840": "熱銷平台",
          "5zmqj6xm8bs0": "熱銷店鋪",
          "5zmqj6xm8ec0": "熱銷產品"
        },
        "index": {
          "5zmr4qhws3s0": "數據總覽"
        }
      },
      "packagingMaterialsStatistics": {
        "components": {
          "60aa48gpg940": "數據統計"
        },
        "constant": {
          "60aa3i4w8840": "包材總數",
          "60aa3i4w8mk0": "訂單使用量",
          "60aa3i4w91s0": "總金額",
          "60aa3i4w9940": "編碼",
          "60aa3i4w9b00": "名稱",
          "60aa3i4w9cs0": "排名",
          "60aa3i4w9e80": "平均單價",
          "60aa3i4w9g00": "小計"
        }
      },
      "productSales": {
        "components": {
          "60aa1gn2j3o0": "規格",
          "60aa1gn2jms0": "商品SKU",
          "60aa24ijr500": "數據統計"
        },
        "constant": {
          "60aa0cmsgck0": "商品種類",
          "60aa0cmsgwk0": "商品銷量",
          "60aa0cmsh000": "商品成本",
          "60aa0cmsh280": "退貨數量",
          "60aa0cmsh4g0": "退款金額",
          "60aa0cmsh780": "銷售金額",
          "60aa0cmsh9s0": "退款成本",
          "60aa0cmshbw0": "銷售訂單量",
          "60aa0vmcdtw0": "商品SKU",
          "60aa0vmcec00": "商品名稱"
        }
      },
      "productStatistics": {
        "components": {
          "60a9yyz55n00": "數據指標趨勢圖",
          "60a9yyz565o0": "百分比(%)"
        },
        "constants": {
          "60a9xumyah40": "日期",
          "60a9xumyav40": "產品總數",
          "60a9xumyay40": "在售產品數",
          "60a9xumyb000": "在售產品佔比",
          "60a9xumyb1s0": "有銷量產品數",
          "60a9xumyb5k0": "動銷率",
          "60a9xumyb740": "新增在售產品數",
          "60a9xumyb8s0": "新增在售產品佔比"
        }
      },
      "profitDetail": {
        "components": {
          "60a9u37djso0": "訂單明細",
          "60a9u37dkc40": "店鋪明細",
          "60a9vuvtmfs0": "店鋪：",
          "60a9vuvtms80": "站點：",
          "60a9wn2oggk0": "產品ID",
          "60a9wn2ogv80": "平台SKU",
          "60a9wn2ogz40": "商品SKU",
          "60a9wn2oh1s0": "商品成本",
          "60a9wn2oh4s0": "平台回款金額：",
          "60a9wn2oh7g0": "利潤：",
          "60a9wn2oha00": "銷售利潤率："
        },
        "constant": {
          "60a9mn7mnho0": "按訂單明細",
          "60a9mn7mo0g0": "按店鋪",
          "60a9mn7mo4c0": "訂單號碼",
          "60a9mn7mo780": "包裹號碼",
          "60a9mn7mo9g0": "運單號碼",
          "60a9mn7mod80": "產品標題",
          "60a9mn7mofk0": "產品ID",
          "60a9mn7mohs0": "平台SKU",
          "60a9mn7mok40": "商品SKU",
          "60a9mn7momg0": "下單時間",
          "60a9mn7mook0": "結算時間",
          "60a9mn7mor40": "付款時間",
          "60a9mn7mouc0": "發貨時間",
          "60a9mn7moww0": "截止發貨時間",
          "60a9skxqv140": "訂單數量",
          "60a9skxqvhc0": "銷售額",
          "60a9skxqvko0": "買家實付",
          "60a9skxqvn80": "預估回款金額",
          "60a9skxqvpo0": "已結算金額",
          "60a9skxqvs00": "待結算金額",
          "60a9skxqvug0": "成本",
          "60a9skxqvyo0": "商品成本",
          "60a9skxqw0o0": "廣告成本",
          "60a9skxqw380": "運營成本",
          "60a9skxqw5g0": "包材成本",
          "60a9skxqw7w0": "其他成本",
          "60a9skxqwbg0": "利潤",
          "60a9skxqwdw0": "成本利潤率",
          "60a9skxqwgg0": "銷售利潤率",
          "60a9skxqwiw0": "今日數據",
          "60a9skxqwl40": "昨日數據",
          "60a9skxqwnw0": "匯總數據",
          "60a9v7pp1n00": "預估回款金額",
          "60a9v7pp2qs0": "訂單原始金額",
          "60a9v7pp30g0": "買家實付",
          "60a9v7pp33g0": "包材費",
          "60a9v7pp3680": "其他成本",
          "60a9v7pp38w0": "利潤",
          "60a9v7pp3bo0": "成本利潤率",
          "60a9v7pp3fo0": "銷售利潤率",
          "60y9bytj9u80": "第三方倉操作費",
          "60y9bytjb480": "第三方倉運費"
        }
      },
      "profitsOverview": {
        "constant": {
          "5zmqie08y6k0": "核心指標",
          "5zmqie08yp40": "核心指標趨勢",
          "5zmqie08ytg0": "支出分析",
          "5zmqie08ywk0": "利潤分析",
          "5zmqie08yzo0": "平台回款金額",
          "5zmqie08z2o0": "環比",
          "5zmqie08z5c0": "包材費",
          "5zmqie08z7w0": "買家實付",
          "5zmqie08zak0": "利潤",
          "5zmqie08zd40": "商品成本",
          "5zmqie08zfo0": "成本利潤率",
          "5zmqie08zig0": "其他成本",
          "5zmqie08zks0": "銷售利潤率"
        },
        "index": {
          "5zmr3g7jskw0": "利潤概覽"
        }
      },
      "saleDetail": {
        "index": {
          "5zmr52c50ng0": "數據總覽",
          "5zmr52c517g0": "產品銷量統計詳情",
          "5zmr52c51bk0": "數據指標趨勢圖",
          "5zmvji9oxrk0": "產品ID",
          "5zmvji9oz0w0": "平台SKU",
          "5zmvji9oz700": "平台",
          "5zmvji9ozao0": "店鋪",
          "5zmvjnye99g0": "產品維度",
          "5zmvlw6cgzg0": "規格",
          "603i2ug6f3k0": "開始時間",
          "603i2ug6gbs0": "結束時間",
          "603i2ug6gjc0": "僅支持查詢近6個月的數據,請重新選擇查詢時間"
        }
      },
      "salesStatistics": {
        "constant": {
          "5zmqf0amgts0": "按SPU(產品)",
          "5zmqf0ami0c0": "按SKU(單品)",
          "5zmqf0ami6o0": "按店鋪",
          "5zmqf0amib40": "價格區間",
          "5zmqf0amifc0": "銷售區間",
          "5zmqf0amilc0": "產品總數",
          "5zmqf0amiqg0": "付款訂單量",
          "5zmqf0amiuo0": "銷量",
          "5zmqf0amj000": "付款總額",
          "5zmqf0amj440": "退款訂單量",
          "5zmqf0amj8c0": "退款金額",
          "5zmqf0amjcw0": "產品標題",
          "5zmqf0amjhc0": "產品ID",
          "5zmqf0amjkw0": "平台SKU"
        },
        "hooks": {
          "5zmr1xvtlyw0": "日期篩選"
        },
        "index": {
          "5zmr18mjbic0": "銷量統計"
        }
      },
      "shopStatistics": {
        "components": {
          "60a9ixf7pqs0": "店鋪明細",
          "60a9ixf7q7w0": "店鋪：",
          "60a9ixf7qcg0": "客戶數(個)：",
          "60a9ixf7qfc0": "訂單量(筆)：",
          "60aacanra200": "數據統計"
        },
        "constant": {
          "60a9gbqw2w80": "客戶數(個)",
          "60a9gbqw3os0": "訂單量(筆)",
          "60a9gbqw3sk0": "訂單總額",
          "60a9gbqw3v80": "付款總額",
          "60a9gbqw3xg0": "退款金額",
          "60a9gbqw3zw0": "客单價",
          "60a9gbqw42c0": "付款訂單量",
          "60a9gbqw4d40": "付款客戶數(個)",
          "60a9gbqw4i40": "退款訂單量",
          "60a9gbqw4po0": "退款客戶數(個)"
        }
      }
    },
    "warehouse": {
      "baseIndex": {
        "5wfvehrixdc0": "今日已入庫包裹",
        "5wfvehrixtk0": "今日已上架SKU",
        "5wfvehrj1ts0": "請選擇倉庫",
        "5wfvehrj1zs0": "簽收入庫",
        "5wfvehrj24w0": "手工入庫",
        "5wfvehrj29k0": "手工出庫",
        "5wfvehrj2ig0": "單品揀貨",
        "5wfvehrj2ng0": "商品查詢",
        "5wfvehriixa0": "倉庫",
        "5wfvehrj2na0": "掃描發貨",
        "619ereolw8g0": "退貨入庫"
      },
      "components": {
        "packageItem": {
          "5rnxjrj9pt00": "請輸入/掃描物流單號、採購單號",
          "5rnxl2ugdn80": "待入庫種類",
          "5rnxl2uge480": "待入庫數",
          "5rnxl2ugey80": "備註"
        }
      },
      "goodsSearch": {
        "baseIndex": {
          "5weqyxxhmtg0": "商品查詢",
          "5weqyxxhnfg0": "掃描商品SKU/商品代碼",
          "5weqyxxhnjw0": "請掃描商品信息",
          "5weqyxxhnms0": "請輸入商品SKU/商品代碼",
          "5weqyxxhnpk0": "加載中",
          "5weqyxxhnsk0": "未查到此商品"
        },
        "components": {
          "GoodsCard": {
            "GoodsInfo": {
              "5wer21zf7rk0": "商品代碼",
              "5wer21zf8jg0": "近7/30/60天銷量"
            },
            "index": {
              "5wer2vtlaf40": "子商品"
            },
            "StorageInfo": {
              "5wer3yv4lx40": "倉位",
              "5wer3yv4mgo0": "庫存總量",
              "5wer3yv4o8g0": "可用",
              "5wer3yv4ofw0": "預占"
            }
          }
        }
      },
      "processStorage": {
        "baseIndex": {
          "5rny2tblyc00": "掃描商品",
          "5rny2tblz7g0": "請輸入/掃描商品SKU、商品代碼",
          "5rny2tblzds0": "結束本次入庫",
          "5rny2tblzkw0": "出庫",
          "5rny2tblzps0": "入庫",
          "5rny2tblztk0": "未找到商品",
          "5rny2tblzx00": "組合品不可入庫",
          "5rny2tbm00g0": "加載中",
          "5rny3yibbno0": "成功",
          "5rny51km7e40": "出庫數量不能超過",
          "5rolykv54d00": "請輸入商品SKU"
        },
        "components": {
          "GoodsPopup": {
            "5rnxodldrdc0": "種",
            "5rnxodlds640": "件",
            "5rnxodldsdc0": "提交"
          },
          "SetQuantityDialog": {
            "5rnxpb2qwsg0": "請輸入數量",
            "5rnxu9u7r2c0": "本次",
            "5ron09jvyh80": "數",
            "5rnxpb2qxg00": "數量不能為空",
            "5rnxu9u7q4w0": "數量不能超過"
          }
        },
        "processGoodsWithStoragePage": {
          "baseIndex": {
            "5rnxzgpf7i00": "商品",
            "5rnxzgpf8co0": "名稱:",
            "5rnxzgpf8io0": "規格:",
            "5rnxzgpf8mg0": "待入庫數:",
            "5rnxzgpf8q40": "本次",
            "5rnxzgpf8tk0": "無倉位",
            "5rnxzgpf8ww0": "未選擇倉位",
            "5rnxzgpf90g0": "刪除",
            "5rnxzgpf93s0": "換倉位",
            "5rnxzgpf97c0": "出庫",
            "5rnxzgpf9ao0": "入庫",
            "5rnxzgpf9e80": "倉位不能為空,請檢查倉位",
            "5rnxzgpf9hs0": "正在操作",
            "5rnxzgpf9l80": "數據異常,請聯系客服",
            "5rny0rcmwbk0": "正在",
            "5werec5q0lc0": "確定"
          }
        },
        "scanStorageCellPage": {
          "baseIndex": {
            "5rny1og9cek0": "掃描倉位",
            "5rny1og9d740": "請掃描/輸入倉位號",
            "5rny1og9ddc0": "請掃描倉位",
            "5rny1og9dgw0": "正在查詢",
            "5roninktevk0": "該商品在此倉位無庫存"
          }
        }
      },
      "putaway": {
        "baseIndex": {
          "5rnyj390bf00": "掃描包裹",
          "5rnyj390c1o0": "請輸入/掃描物流單號、採購單號",
          "5rnxm6vsad80": "請掃描包裹",
          "5rnyj390c7g0": "待簽收採購單",
          "5rnyj390cb40": "加載中",
          "5rnyj390ce80": "暫無數據",
          "5rnyj390cho0": "採購單簽收成功"
        },
        "components": {
          "WaitReceivePurchaseOrderList": {
            "5rnyh09ydbg0": "暫無數據"
          }
        },
        "purchaseOrderInfoPage": {
          "baseIndex": {
            "5rnyibnc0h40": "掃描包裹",
            "5rnyibnc14g0": "名稱:",
            "5rnyibnc1a00": "規格:",
            "5rnyibnc1dw0": "代碼:",
            "5rnyibnc1hc0": "已經到底啦",
            "5rnyibnc1l00": "直接入庫",
            "5rnyibnc1ow0": "掃描商品入庫",
            "5rnyibnc1sg0": "直接入庫後,庫存將增加,確定入庫?",
            "5rnyibnc1w80": "正在入庫中..."
          }
        }
      },
      "scanReturn": {
        "components": {
          "WaitReturnGoodsList": {
            "619evhn1suw0": "待入庫種類",
            "619evhn1txg0": "待入庫數",
            "619evhn1u580": "暫無數據"
          }
        },
        "hooks": {
          "useReturnGoods": {
            "619eub7jj1s0": "正在入庫中...",
            "61wzjfwnawk0": "入庫數量不能超過{0}個"
          },
          "useScan": {
            "619euwhlq5o0": "加載中",
            "619euwhlqyg0": "暫無數據"
          }
        },
        "returnOrderInfoPage": {
          "index": {
            "619etj4urs80": "退貨入庫",
            "619etj4usrk0": "名稱:",
            "619etj4usxs0": "規格:",
            "619etj4ut1s0": "已經到底啦",
            "619etj4ut540": "直接入庫",
            "619etj4ut980": "掃描商品入庫",
            "619etj4uteg0": "直接入庫後,庫存將增加,確定入庫?"
          }
        },
        "constant": {
          "619espdpd100": "掃描包裹",
          "619espdpe9k0": "待入庫退貨單"
        },
        "index": {
          "619es2vhols0": "請掃描包裹",
          "619es2vho3o0": "退貨入庫",
          "619es2vhoi80": "請輸入/掃描運單號碼、訂單號碼或包裹號碼",
          "619es2vhooo0": "退貨單入庫成功"
        }
      },
      "scanShipment": {
        "components": {
          "List": {
            "5wsld4f61gw0": "暫無數據"
          },
          "OrderPackageItemMedia": {
            "5wsld4f61gw0": "1、上傳的圖片/視頻可在圖片空間中查看",
            "5wsld4f61gw1": "2、視頻不可超過30M,時長不可超過1分鐘",
            "5wsld4f61gw2": "上傳圖片",
            "5wsld4f61gw3": "上傳視頻",
            "5wsld4f61gw4": "選擇視頻",
            "5wsld4f61gw5": "圖片",
            "5wsld4f61gw6": "視頻",
            "5wsld4f61gw7": "時長不可超過1分鐘"
          },
          "PackageItemGoods": {
            "5wsldq8io7w0": "規格"
          },
          "ShipmentButton": {
            "5wsle2o8bvc0": "發貨"
          }
        },
        "hooks": {
          "useList": {
            "5wslbuh0fts0": "該包裹本次已掃描",
            "5wslbuh0j1c0": "加載中",
            "5wslbuh0j8g0": "暫無數據"
          },
          "useShipmentPackage": {
            "5wslcgxgpio0": "操作中",
            "5wslcgxgu5g0": "發貨失敗",
            "5wslcgxgug00": "發貨成功"
          }
        },
        "index": {
          "5wsl89cm3gw0": "掃描發貨",
          "5wsl89cm7z80": "掃描或輸入包裹號/運單號",
          "5wsl89cm8980": "已掃描",
          "5wsl89cm8ds0": "請掃描包裹信息",
          "5wsl89cm8hc0": "加載中",
          "5wsl89cm8hc1": "掃描後自动发货"
        },
        "scannedListPage": {
          "index": {
            "5wslasiw3rc0": "已掃描",
            "5wslasiw8r40": "確定要移除包裹?",
            "5wslasiw9200": "返回後,已掃描的包裹列表將清空,確定要繼續?",
            "5wslasiw9940": "全部",
            "5wslasiw9f40": "暫無可發貨包裹"
          }
        }
      },
      "singlePick": {
        "baseIndex": {
          "5wesij4wgig0": "單品揀貨",
          "5wesij4whps0": "掃描/輸入波次號",
          "5wesij4whv40": "確定要標記為完成狀態?",
          "5wesij4whyk0": "為了避免重複揀貨,建議和賬號所屬用戶確認或查看操作日誌後,再開始揀貨",
          "5wesij4wi1s0": "標記後,波次{0}將無法揀貨",
          "5wesij4wi5s0": "賬號:{0}於{1}操作揀貨",
          "5wesij4wi0s0": "且波次將進入為待包裝狀態"
        },
        "components": {
          "WaveList": {
            "WaveItem": {
              "5wesqu5fcgs0": "已揀/待揀",
              "5wesqu5fi8s0": "商品信息",
              "5wesqu5fihk0": "創建時間",
              "5wesqu5fimg0": "創建人",
              "5wesqu5fiv00": "更多",
              "5wesqu5fizs0": "開始揀貨",
              "5wesqu5fj3w0": "標記完成",
              "5wesqu5fj800": "揀貨日誌",
              "5wesqu5fjck0": "{0}種SKU,共{1}個"
            }
          }
        },
        "hooks": {
          "useOperate": {
            "5wespf869ys0": "加載中",
            "5wespf86efs0": "繼續揀貨",
            "5wespf86et80": "揀貨日誌",
            "5wespf86eys0": "波次狀態已更新,請下拉刷新頁面查看"
          }
        },
        "subPages": {
          "pickLog": {
            "baseIndex": {
              "5weyeh0dlbs0": "揀貨日誌"
            },
            "components": {
              "LogItem": {
                "5weygrs8su40": "商品SKU",
                "5weygrs8xvc0": "揀貨數量"
              }
            }
          },
          "picking": {
            "baseIndex": {
              "5wey0wt55a40": "掃描/輸入SKU、商品代碼",
              "5wey0wt64ns0": "種SKU,",
              "5wey0wt650g0": "共",
              "5wey0wt65b00": "個",
              "5wey1e5fupo0": "剩餘揀貨:"
            },
            "components": {
              "SetPickQuantityDialog": {
                "5wey82q92us0": "填寫已揀數量",
                "5wey82q99rg0": "請輸入",
                "5wey82q9a100": "請輸入已揀數量",
                "5wey82q9a1g0": "已揀數量不能大於待揀數量:{0}"
              },
              "SkuItem": {
                "5wey9z9uhxs0": "商品代碼",
                "5wey9z9uoe00": "倉位",
                "5wey9z9uook0": "已揀",
                "5wey9z9uoyw0": "待揀"
              },
              "WavePickFinishDialog": {
                "5weycylwol80": "提示",
                "5weycylwsss0": "我已知曉",
                "5weycylwt1c0": "當前波次揀貨已完成!"
              }
            },
            "hooks": {
              "usePicking": {
                "5wey5ujb4xs0": "當前波次不存在此商品",
                "5wey5ujbaeo0": "商品SKU:{0}已完成揀貨"
              }
            }
          }
        }
      }
    }
  },
  "system": {
    "confirm": "確定",
    "cancel": "取消",
    "pleaseSelect": "請選擇",
    "5rnxe856we80": "提示",
    "5rnxe856wl00": "確認",
    "loading": "正在加載",
    "copySuccess": "複製成功",
    "emptyShelfCell": "無倉位",
    "versionInfo": "版本信息",
    "language": {
      "toggle": "切換語言",
      "selectEmpty": "請選擇語言"
    },
    "tabBar": {
      "stat": "數據",
      "home": "倉庫",
      "my": "我的"
    },
    "update": {
      "title": "更新提示",
      "forceUpdate": "發現新版本,立即重啟",
      "fail": "更新失敗",
      "newVersion": "發現新版本",
      "updateNow": "立即更新",
      "updateLater": "下次更新"
    }
  }
}