import useUserStore from '@/store/useUserStore';
import { logout } from '@/store';

export default {
  async onLaunch() {
    const userStore = useUserStore();
    const errorHandler = () => {
      // 使用redirectTo跳转 防止后退白屏
      logout('reLaunch').then(() => this.closeSplashscreen());
    };

    // 每次打开应用 重新登录
    // #ifndef H5
    const isReLoginSuccess = await userStore.reLogin();
    if (!isReLoginSuccess) {
      return errorHandler();
    }
    // #endif

    uni
      .switchTab({
        url: '/pages/warehouse/index',
      })
      .finally(() => {
        this.closeSplashscreen();
      });
  },
  methods: {
    closeSplashscreen() {
      // #ifdef APP-PLUS
      plus.navigator.closeSplashscreen();
      // #endif
    },
  },
};
